import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { Button } from '@mui/material';
import { useGlobalContext } from '../../Context/GlobalContext';

import { postApi, getAll } from '../../apiClient';

import './EditStoreDetailsStyles.css';

const EditStoreDetailsComponent = ({onboardingStoreDetailsData}) => {

  const [editCategory, setEditCategory] = useState('');
  const [editCategoryError, setEditCategoryError] = useState(false);
  const [editCategoryHelperText, setEditCategoryHelperText] = useState('');

  const [editSubcategory, setEditSubcategory] = useState([]);
  const [editSubcategoryError, setEditSubcategoryError] = useState(false);
  const [editSubcategoryHelperText, setEditSubcategoryHelperText] = useState('');

  const [editKeyAudience, setEditKeyAudience] = useState('');
  const [editKeyAudienceError, setEditKeyAudienceError] = useState(false);
  const [editKeyAudienceHelperText, setEditKeyAudienceHelperText] = useState('');

  const [editOverallContentTone, setEditOverallContentTone] = useState([]);
  const [editOverallContentToneError, setEditOverallContentToneError] = useState(false);
  const [editOverallContentToneHelperText, setEditOverallContentToneHelperText] = useState('');

  const [editKeyTarget, setEditKeyTarget] = useState('');
  const [editKeyTargetError, setEditKeyTargetError] = useState(false);
  const [editKeyTargetHelperText, setEditKeyTargetHelperText] = useState('');

  const [editStoreDescription, setEditStoreDescription] = useState('');
  const [editStoreDescriptionError, setEditStoreDescriptionError] = useState(false);
  const [editStoreDescriptionHelperText, setEditStoreDescriptionHelperText] = useState('');

  const [editStoreDetailscategoryList, setEditStoreDetailsCategoryList] = useState([]);
  const [editStoreDetailsSubCategoryList, setEditStoreDetailsSubCategoryList] = useState([]);
  const [editStoreDetailsContentToneList, setEditStoreDetailsContentToneList] = useState([]);
  const [editStoreDetailsKeyAudienceList, setEditStoreDetailsKeyAudienceList] = useState([]);
  const [editStoreDetailskeyTargetsList, setEditStoreDetailsKeyTargetsList] = useState([]);
  const { setLoading, showToast } = useGlobalContext();

  useEffect(() => {
    getCategory();
    getOthersAttribute();
  }, []);

  useEffect(() => {
    if (onboardingStoreDetailsData) {
      const lastUpdateCategory = onboardingStoreDetailsData?.storeAttribute?.category[0]|| "";
      const lastUpdateSubcategory = onboardingStoreDetailsData?.storeAttribute?.subCategory;
      const lastUpdateKeyAudience = onboardingStoreDetailsData?.storeAttribute?.keyAudience[0] || "";
      const lastUpdateOverContentTone = onboardingStoreDetailsData?.storeAttribute?.contentTone;
      const lastUpdateKeytarget = onboardingStoreDetailsData?.storeAttribute?.keyTargets[0] || "";
      const lastUpdateStoreDes = onboardingStoreDetailsData?.description;

      // Convert to arrays if they are objects
      const lastUpdateSubcategoryArray = Array.isArray(lastUpdateSubcategory) ? lastUpdateSubcategory : Object.values(lastUpdateSubcategory || {});
      const lastUpdateOverContentToneArray = Array.isArray(lastUpdateOverContentTone) ? lastUpdateOverContentTone : Object.values(lastUpdateOverContentTone || {});

      setEditCategory(lastUpdateCategory);
      setEditSubcategory(lastUpdateSubcategoryArray);
      setEditKeyAudience(lastUpdateKeyAudience);
      setEditOverallContentTone(lastUpdateOverContentToneArray);
      setEditKeyTarget(lastUpdateKeytarget);
      setEditStoreDescription(lastUpdateStoreDes);
    }
  }, [onboardingStoreDetailsData])

  const getCategory = async () => {
    let response = await getAll('api/category')
    const { category } = response.data;
    if (response.status === 200 && category.length > 0) {
      setEditStoreDetailsCategoryList(category)
    }
  }

  const categoryHandleChnage = async (value) => {
    setEditCategory(value)
    getSubcategory(value)
  }

  const getSubcategory = async (value) => {
    let params = {
      categoryName: [value]
    }
    let response = await postApi('api/sub-category', params)
    const { category } = response.data;
    if (response.status === 200 && category.length > 0) {
      setEditStoreDetailsSubCategoryList(category)
    }
  }

  const getOthersAttribute = async () => {
    let response = await getAll('api/default-attribute')
    const { contentTone, keyAudience, keyTargets } = response.data;
    if (response.status === 200 && contentTone.length > 0 && keyAudience.length > 0 && keyTargets.length > 0) {
      setEditStoreDetailsKeyAudienceList(keyAudience)
      setEditStoreDetailsContentToneList(contentTone)
      setEditStoreDetailsKeyTargetsList(keyTargets)
    }
  }

  const handleEditStoreDetails = async () => {
    try{
      setLoading(true);
      let valid = true;
      if (!editCategory) {
        setEditCategoryError(true);
        setEditCategoryHelperText('Please choose the category');
        valid = false;
        setLoading(false);
      } else {
        setEditCategoryError(false);
        setEditCategoryHelperText('');
      }

      if (editSubcategory.length < 1) {
        setEditSubcategoryError(true);
        setEditSubcategoryHelperText('Please choose the sub category');
        valid = false;
        setLoading(false);
      } else {
        setEditSubcategoryError(false);
        setEditSubcategoryHelperText('');
      }

      if (!editKeyAudience) {
        setEditKeyAudienceError(true);
        setEditKeyAudienceHelperText('Please choose the key audience');
        valid = false;
        setLoading(false);
      } else {
        setEditKeyAudienceError(false);
        setEditKeyAudienceHelperText('');
      }

      if (editOverallContentTone.length < 1) {
        setEditOverallContentToneError(true);
        setEditOverallContentToneHelperText('Please choose the overall content tone');
        valid = false;
        setLoading(false);
      } else {
        setEditOverallContentToneError(false);
        setEditOverallContentToneHelperText('');
      }

      if (!editKeyTarget) {
        setEditKeyTargetError(true);
        setEditKeyTargetHelperText('Please choose the key target');
        valid = false;
        setLoading(false);
      } else {
        setEditKeyTargetError(false);
        setEditKeyTargetHelperText('');
      }

      if (!editStoreDescription) {
        setEditStoreDescriptionError(true);
        setEditStoreDescriptionHelperText('Please choose the store description');
        valid = false;
        setLoading(false);
      } else {
        setEditStoreDescriptionError(false);
        setEditStoreDescriptionHelperText('');
      }

      if(valid){
        let editStoreDetailsData = {
          storeId:localStorage.getItem("storeId"),
          description:editStoreDescription,
          attributes : {
            category : [editCategory],
            subCategory:editSubcategory,
            keyAudience:[editKeyAudience],
            contentTone:editOverallContentTone,
            keyTargets:[editKeyTarget],
            digitalPromotionPerYear: onboardingStoreDetailsData?.storeAttribute?.digitalPromotionPerYear,
            offerDiscount: onboardingStoreDetailsData?.storeAttribute?.offerDiscount,
            instructionToTrain: onboardingStoreDetailsData?.storeAttribute?.instructionToTrain,
          }
        }

        let response = await postApi('api/update-store-attribute',editStoreDetailsData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          showToast(message, 'success');
          setLoading(false);
        }
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  }
  //const isSelected = (value) => editOverallContentTone?.includes(value);
  return (
    <div className='editStoreDetailsContainer'>
      <div className='editStoreDetailsWrapper'>
        {/* <p className='headingFirstStep'>Store details</p> */}
        <span className='locationInfo'>Tell us about your store</span>
        <form className='storeDetailForm' noValidate>
          <FormControl variant="outlined" margin="normal">
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="editCategory"
              label="editCategory"
              required
              name="editCategory"
              value={editCategory}
              onChange={(e) => categoryHandleChnage(e.target.value)}
              error={editCategoryError}
            >
              {editStoreDetailscategoryList.map((cat, index) =>
                <MenuItem key={index} value={cat.categoryId}>{cat.categoryName}</MenuItem>
              )}
            </Select>
            <FormHelperText>{editCategoryHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="subcategory-label">Sub Category</InputLabel>
            <Select
              multiple
              labelId="subcategory-label"
              id="editSubcategory"
              label="Sub Category"
              required
              name="editSubcategory"
              value={editSubcategory}
              onChange={(e) => setEditSubcategory(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              error={editSubcategoryError}
            >
              {editStoreDetailsSubCategoryList.map((subcat, index) =>
                <MenuItem key={index} value={subcat.categoryName}>
                  <Checkbox checked={editSubcategory?.indexOf(subcat.categoryName) > -1} />
                  <ListItemText primary={subcat.categoryName} />
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{editSubcategoryHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="role-label">Key Audience</InputLabel>
            <Select
              labelId="key-audience-label"
              id="editKeyAudience"
              label="Key Audience"
              required
              name="editKeyAudience"
              value={editKeyAudience}
              onChange={(e) => setEditKeyAudience(e.target.value)}
              error={editKeyAudienceError}
            >
              {editStoreDetailsKeyAudienceList.map((keyAud, index) =>
                <MenuItem key={keyAud.keyAudience} value={keyAud.keyAudience}>
                  <ListItemText
                    primary={keyAud.keyAudience}
                    secondary={<Typography variant="body2" sx={{ fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43' }}>Years born : {keyAud.bornYear}</Typography>}
                  />
                </MenuItem>
              )}

            </Select>
            <FormHelperText>{editKeyAudienceHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="conetent-tone-label">Overall Content Tone</InputLabel>
            <Select
              multiple
              labelId="conetent-tone-label"
              id="editOverallContentTone"
              label="Overall Content Tone"
              name="editOverallContentTone"
              value={editOverallContentTone}
              onChange={(e) => setEditOverallContentTone(e.target.value)}
              renderValue={(selected) => selected.join(', ')}
              error={editOverallContentToneError}
            >
              {editStoreDetailsContentToneList.map((tone, index) =>
                // <MenuItem value={tone}>{tone}</MenuItem>
                <MenuItem key={index} value={tone}>
                  <Checkbox 
                  //checked={isSelected(tone)} 
                  checked={editOverallContentTone?.indexOf(tone) > -1}
                  />
                  <ListItemText primary={tone} />
                </MenuItem>
              )}
            </Select>
            <FormHelperText>{editOverallContentToneHelperText}</FormHelperText>
          </FormControl>

          <FormControl variant="outlined" margin="normal">
            <InputLabel id="key-target-label">Key Target</InputLabel>
            <Select
              labelId="key-target-label"
              id="editKeyTarget"
              label="Key Target"
              name="editKeyTarget"
              value={editKeyTarget}
              onChange={(e) => setEditKeyTarget(e.target.value)}
              error={editKeyTargetError}
            >
              {editStoreDetailskeyTargetsList.map((keyTar, index) =>
                <MenuItem key={index} value={keyTar}>{keyTar}</MenuItem>
              )}
            </Select>
            <FormHelperText>{editKeyTargetHelperText}</FormHelperText>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="editStoreDescription"
            label="Store Description"
            name="editStoreDescription"
            autoComplete="editStoreDescription"
            multiline
            rows={4}
            value={editStoreDescription}
            onChange={(e) => setEditStoreDescription(e.target.value)}
            error={editStoreDescriptionError}
            helperText={editStoreDescriptionHelperText}
          />
        </form>
      </div>
      <div className='editStoreDetailsButton'>
        <Button
          onClick={()=> handleEditStoreDetails()}
          variant="primary"
          fullWidth
        >
          Update
          <img
            src={process.env.REACT_APP_URL + "next-long-arrow-right.svg"}
            alt="next"
          />
        </Button>
      </div>
    </div>
  )
}

export default EditStoreDetailsComponent
