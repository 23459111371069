import API from "../ApiClient/ApiClient";
import { VIEW_CONVERSATION_CUSTOMERLIST_ROUTES_URL ,VIEW_CONVERSATION_CUSTOMER_MESSAGE_ROUTES_URL} from "../Constant/Constant";


export function viewConversationCustomerListGetApi(params = "") {
    return API.get(`${VIEW_CONVERSATION_CUSTOMERLIST_ROUTES_URL.VIEW_CONVERSATION_CUSTOMERLIST_DETAILS}?promotionId=${params}`);
}
export function viewConversationMessageGetApi(promotionId,customerId) {
    return API.get(`${VIEW_CONVERSATION_CUSTOMER_MESSAGE_ROUTES_URL.VIEW_CONVERSATION_CUSTOMER_MESSAGE_DETAILS}?promotionId=${promotionId}&customerId=${customerId}`);
}

