import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import {postApi}from '../../apiClient';
import { useGlobalContext } from '../../Context/GlobalContext';
import  './ForgotPasswordStyles.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (event) => {
    try{
      event.preventDefault();
      setLoading(true);
      let valid = true;

      if (!validateEmail(email)) {
        setEmailError(true);
        setEmailHelperText('Please enter a valid email address.');
        valid = false;
        setLoading(false);
      } else {
        setEmailError(false);
        setEmailHelperText('');
      }

      if (valid) {
        // Handle form submission
        let formData = {
          email : email,
        }
        let response = await postApi('api/forgot-password',formData)
        if (response.status == 200) {
          showToast('A link has been sent to your email. Please check your email and reset your password', 'success');
          //navigate('/reset-password');
          setLoading(false);
        }
      }
    } catch (error){
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  };
 
  return (
        <form className='forgotForm' noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
            helperText={emailHelperText}
          />
          <div className="mt-4">
          <Button
            type="submit"
            fullWidth
            variant="primary"
          >
              Submit
          </Button>
          </div>
          <div className='mt-5'><p className='gotPasswordText'>Got your password?<Link to="/login" className='btnLoginForgotPage'>Login now</Link></p></div>
        </form>
  );
}

export default ForgotPassword;
