import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SalesReportsBarcharts from '../AnalyticsChartsComponent/SalesReportsBarcharts';
import OrderListBarCharts from '../AnalyticsChartsComponent/OrderListBarCharts';
import MonthSalesLineCharts from '../AnalyticsChartsComponent/MonthSalesLineCharts';
import "./StoreDemographics.css"

const StoreDemographics = () => {

  const [monthSelect, setMonthSelect] = useState('');
  const scrollRef = useRef(null);

  const handleChange = (event) => {
    setMonthSelect(event.target.value);
  };

  const products = [
    { name: 'Miller Dickey Jacket', image: 'MillerDickeyJacket.png' },
    { name: 'Nagano Shirt Dress', image: 'Nagano Shirt Dress.png' },
    { name: 'Andrea Plaid Jacket', image: 'Andrea Plaid Jacket.png' },
    { name: 'Orlinda Vest', image: 'Orlinda Vest.png' },
    { name: 'Cap Toe Slingback', image: 'Cap Toe Slingback.png' },
    { name: 'Nagano Shirt Dress', image: 'Nagano Shirt Dress.png' },
    { name: 'Andrea Plaid Jacket', image: 'Andrea Plaid Jacket.png' },
    { name: 'Orlinda Vest', image: 'Orlinda Vest.png' },
    { name: 'Cap Toe Slingback', image: 'Cap Toe Slingback.png' },
    { name: 'Cap Toe Slingback', image: 'Cap Toe Slingback.png' },
    { name: 'Nagano Shirt Dress', image: 'Nagano Shirt Dress.png' },
    { name: 'Andrea Plaid Jacket', image: 'Andrea Plaid Jacket.png' },
    { name: 'Orlinda Vest', image: 'Orlinda Vest.png' },
    { name: 'Cap Toe Slingback', image: 'Cap Toe Slingback.png' },
  ];


  const handleNext = () => {
    const { scrollWidth, offsetWidth, scrollLeft } = scrollRef.current;

    // Check if we've reached the end
    if (scrollLeft + offsetWidth >= scrollWidth) {
      // If at the end, scroll back to the start
      scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      // Otherwise, scroll to the next image
      scrollRef.current.scrollBy({ left: offsetWidth, behavior: 'smooth' });
    }
  };



  const handlePrev = () => {
    console.log("prev");
    scrollRef.current.scrollBy({ left: -scrollRef.current.offsetWidth, behavior: 'smooth' });
  };



  return (
    <div>
      <Grid container className='storeDemographicsContainer'>
        <Grid item xs={12} md={8} className='storeDemographicsContentWrapper'>
          <div className="salesSummaryContainer">
            <div className="salesSummaryItem">
              <div className="totalSalesTitle">Total Sales</div>
              <div className="salesAmountContainer">
                <div className="totalSalesAmount">$50,000</div>
                <div>
                  <SparkLineChart data={[1, 2, 4, 6, 4, 2, 5, 7, 2, 4, 6]} colors={['#587EFC']} height={40} width={40} />
                </div>
              </div>
              <div className="salesDetails">
                <div className="salesSummarySubtitle">Since last week</div>
                <div className="salesPercentage">13.4%</div>
              </div>
            </div>
            <div className="orderSummaryItem">
              <div className="totalOrderTitle">Total Orders</div>
              <div className="orderAmountContainer">
                <div className="totalOrder">4865</div>
                <div>
                  <SparkLineChart data={[1, 2, 4, 6, 4, 2, 5, 7, 2, 4, 6]} colors={['#FF5D5D']} height={40} width={40} />
                </div>
              </div>
              <div className="orderDetails">
                <div className="orderSummarySubtitle">Since last week</div>
                <div className="orderPercentage">13.4%</div>
              </div>
            </div>
            <div className="salesSummaryItem">
              <div className="totalSalesTitle">Total Sales</div>
              <div className="salesAmountContainer">
                <div className="totalSalesAmount">$50,000</div>
                <div>
                  <SparkLineChart data={[1, 2, 4, 6, 4, 2, 5, 7, 2, 4, 6]} colors={['#56B8C4']} height={40} width={40} />
                </div>
              </div>
              <div className="salesDetails">
                <div className="salesSummarySubtitle">Since last week</div>
                <div className="salesPercentage">13.4%</div>
              </div>
            </div>
          </div>
          <div className="salesReportContainer">
            <div className="salesReportWrapper">
              <div className='salesHeaderSection'>
                <div className="salesReportTitle">Sales Report</div>
                <div className="salesTimePeriodButtonSection">
                  <button className="salesTimePeriodButton">12 Months</button>
                  <button className="salesTimePeriodButton">6 Months</button>
                  <button className="salesTimePeriodButton">30 Days</button>
                  <button className="salesTimePeriodButton">7 Days</button>
                </div>
              </div>
              <div className="averageMonthlySummary">
                <div className="averageMonthlyTitle">Avg per month</div>
                <div className="averageMonthlyAmount">$38,500</div>
              </div>
              <div>
                <SalesReportsBarcharts />
              </div>
            </div>
          </div>
          <div className="topSellingProductsContainer">
            <div className="topSellingProductsTitle">Top Selling Products</div>
            <Carousel
              next={handleNext}
              prev={handlePrev}
              indicators={false}
              navButtonsAlwaysVisible={true}
              navButtonsProps={{
                style: {
                  backgroundColor: 'rgba(151, 99, 255, 0.50)',
                  width: "28px",
                  height: "27px",
                  borderRadius: "3px",
                  margin: '0px',
                },
              }}
            >
              <div className="topSellingProductsList" ref={scrollRef}>
                {products.map((product, index) => (
                  <div key={index} className="topSellingProductItem">
                    <div className='topSellingProductImageWrapper'>
                      <img src={`${process.env.REACT_APP_URL}${product.image}`} alt={product.name} style={{ width: '100%', borderRadius: '4px' }} />
                    </div>
                    <div className="topSellingProductName">{product.name}</div>
                  </div>
                ))}
              </div>
            </Carousel>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className='storeDemographicsSecondContentWrapper'>
          <div className="orderListContainer">
            <div className="orderListHeader">
              <div className="orderListTitle">Orders List</div>
              <div className="timeFrameLabel">Last 6 months</div>
            </div>
            <div>
              <OrderListBarCharts />
            </div>
          </div>
          <div className="monthsalesReportContainer">
            <div className="monthsalesReportHeader">
              <div className="monthsalesReportTitle">Monthly Sales</div>
              <div>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={monthSelect}
                    label="Month"
                    onChange={handleChange}
                    className="monthSalesReportDropdown"
                  >
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="monthlyTotalWrapper">
              <div className="monthlyTotalLabel">Total this month</div>
              <div className="monthlyTotalValueContainer">
                <div className="monthlyTotalValue">$38,500</div>
                <div className="percentage-change">13.4%</div>
              </div>
              <MonthSalesLineCharts />
            </div>
            <div className="monthsalesLineChartContainer">

            </div>
          </div>
        </Grid>
      </Grid>
    </div >
  )
}

export default StoreDemographics
