import React, { useEffect, useState } from 'react';
import { BarChart, } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const SalesReportsBarcharts = () => {
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 600 ? "550" : 600);
    const [chartHeight, setChartHeight] = useState(window.innerWidth < 600 ? "300" : 300);


    const salesReportDate = [2000, 3000, 2000, 2780, 1890, 2390, 3490, 2000, 2780, 1890, 2390, 3490];
    const salesMonths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
        'Nov', 'Dec'
    ];
    const handleResize = () => {
        setChartWidth(window.innerWidth < 600 ? "550" : 600);
        setChartHeight(window.innerWidth < 600 ? "300" : 300);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <BarChart
                sx={() => ({
                    [`.${axisClasses.root}`]: {
                        [`.${axisClasses.tickLabel}`]: {
                            fill: '#5F73AE',
                        },
                    },
                })}
                width={chartWidth}
                height={chartHeight}
                borderRadius={5}
                grid={{ horizontal: true }}
                series={[{
                    data: salesReportDate,
                    color: '#EEE5FF',
                }]}
                xAxis={[{
                    data: salesMonths,
                    scaleType: 'band',
                    disableLine: true,
                    disableTicks: true,
                    tickFontSize: "12",
                    tickLabelPlacement: "middle",
                    tickLabelStyle: {
                        color: "#5F73AE",
                        fontWeight: "bold",
                    }
                }]}
                leftAxis={null}
            />

        </div>
    )
}

export default SalesReportsBarcharts
