import API from "../ApiClient/ApiClient";
import { SHOP_ROUTES_URL,
    SHOP_BRANCH_ROUTES_URL,
    SHOP_BRANCH_LIST_ROUTES_URL
 } from "../Constant/Constant";


export function shopPostApi(params = {}) {
    return API.post(SHOP_ROUTES_URL.SHOP_DETAILS, params);
}
export function shopBranchPostApi(params = {}) {
    return API.post(SHOP_BRANCH_ROUTES_URL.SHOP_BRANCH_DETAILS, params);
}
export function shopBranchListGetApi(params = "") {
    return API.get(`${SHOP_BRANCH_LIST_ROUTES_URL.SHOP_BRANCH_LIST_DETAILS}/${params}`);
  }