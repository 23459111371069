import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import {postApi}from '../../apiClient';
import { useGlobalContext } from '../../Context/GlobalContext';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import  './LoginStyles.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const { setLoading, showToast } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    try{
      event.preventDefault();
      setLoading(true);
      let valid = true;

      if (!validateEmail(email)) {
        setEmailError(true);
        setEmailHelperText('Please enter a valid email address.');
        valid = false;
        setLoading(false);
      } else {
        setEmailError(false);
        setEmailHelperText('');
      }

      if (password.length < 6) {
        setPasswordError(true);
        setPasswordHelperText('Password must be at least 6 characters long.');
        valid = false;
        setLoading(false);
      } else {
        setPasswordError(false);
        setPasswordHelperText('');
      }

      if (valid) {
        let formData = {
          email : email,
          password : password
        }
        // Handle form submission
        let response = await postApi('api/login',formData)
        const { access_token, user, storeId	 } = response?.data;
        if (access_token) {
          localStorage.setItem('accessToken', access_token);
          localStorage.setItem('ownerEmail', user?.email);
          localStorage.setItem('userId',user?.userId);
          localStorage.setItem('storeExists',user?.storeExists); 
          localStorage.setItem('companyName',user?.companyName);
          localStorage.setItem('fullName',user?.fullName);
          localStorage.setItem('storeId',storeId);
          //setToastMessage({ message: 'Login successful!', type: 'success' });
          showToast('Login successful!', 'success');
          navigate('/app/dashboard');
          setLoading(false);
        }

      }
    } catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      if(error?.response?.data?.err?.isPayment === false && error?.response?.data?.err?.isVerified === true ){
        localStorage.setItem('fsap', error.response.data.err.email);
        navigate('/subscription-plan');
      }
      console.log("error",error)
    }
  };
 
  return (
    
    <form className='loginForm' noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={emailError}
        helperText={emailHelperText}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={passwordError}
        helperText={passwordHelperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className='flex justify-between'>
        <div className="text-left text-custom-gray">
          <Checkbox label="Remember Me" className='rememberMe'/> Remember Me
        </div>
        <div className="text-right"> 
          <p className='mt-0.5'><Link to="/forgot-password" className='btnForgotPassword'>Forgot Password?</Link></p>
        </div>
        
      </div>
      <div>
      <Button
        type="submit"
        fullWidth
        variant="primary"
      >
          Sign In
      </Button>
      </div>
      <div className='mt-5'>
        <p className='dontAccountText'>
          Don’t have an account?<Link to="/signup" className='btnSignupLoginPage'>Sign Up here</Link>
        </p>
      </div>
    </form>
  );
}

export default Login;
