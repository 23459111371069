import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "./ShopBranckList.css";
import { shopBranchListGetApi } from '../../API/ShopAndShopBranch/ShopApi';
import { useGlobalContext } from '../../Context/GlobalContext';

const ShopBranchList = () => {

  const [shopBranchList, setShopBranchList] = useState([]);
  const storeId = localStorage.getItem("storeId");
  const navigate = useNavigate();
  const { setLoading, showToast } = useGlobalContext();

  const handleCreateButtonClicked = () => {
    navigate('/app/shop-branch');
  };

  useEffect(() => {
    getShopBranchList();
  }, []);

  const getShopBranchList = async () => {
    setLoading(true);
    let response = await shopBranchListGetApi(storeId);
    if (response.status === 200) {
      setShopBranchList(response.data.branch);
      setLoading(false);
    } else {
      console.error("Error fetching shop branches:");
      setLoading(false);
    }
  };

  return (
    <div className='shopBranchlistContainer'>
      {shopBranchList.length > 0 ? (
        <>
          <div className='createBranchButtonContainer'>
            <Button
              variant="primary"
              className="createBranchButton"
              onClick={handleCreateButtonClicked}
            >
              Create Store Branch
            </Button>
          </div>

          <Grid container spacing={3} p={2}>
            {shopBranchList.map((branch, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <div className='shopBranchListCard'>
                  <div className='shopBranchListName'>{branch.branchName}</div>
                  <div className='shopBranchListEmail'>{branch.branchEmail}</div>
                  <div className='shopBranchListContact'>{branch.branchContact}</div>
                  <div className='shopBranchListAddress'>{branch.address}</div>
                  <div className='shopBranchListURL'>{branch.ecommerceUrl}</div>
                  <div className='shopBranchListOnline'>
                     Online: {branch.isOnline ? 'Yes' : 'No'}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <div className='noBranchListContainer'>
          <div className='noBrancheListWrapper'>
            <div className='noBrancheListCard'>
              <p className="noBranchListTitle">No store branches found</p>
              <img src={process.env.REACT_APP_URL + 'login-banner-old.png'} alt="Rectangle" className='nobrancheListImage' />
              <div className='flex justify-center align-center mb-2'>
                <Button
                  variant="primary"
                  href='/app/shop-branch'
                >
                  Create Store Branch
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div >
  )
}

export default ShopBranchList
