import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { TextField, Button, Grid } from '@mui/material';
import  './SubscriptionPlanStyles.css';
import { useGlobalContext } from '../../Context/GlobalContext';
import { postPaymentApi}from '../../apiClient';
import { Link, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51O1qA4DnX1DMWAG3NxaJDkBsCWPmcNeiE9guZJcY93ZkxqkdNu90vI8wtkPVGu1LYggTdK04rENEvRxsIX8NCoA400o35XAKKD');

const CheckoutForm = ({ planId, setSubscriptionPayModalOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(localStorage.getItem('fsap'));
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();

    const handleSubmit = async (event) => {
      setLoading(true);
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      // Create a payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: { email },
      });

      if (error) {
          setLoading(false);
          showToast(error.message, 'error');
      } else {
        let subscriptionPayload = { email, paymentMethodId: paymentMethod.id, planId }
        //console.log("subscriptionPayload",subscriptionPayload)
        setSubscriptionPayModalOpen(false);
        let response = await postPaymentApi('api/subscription',subscriptionPayload)
        if(response?.data?.subscription?.status === 'active') {
          if(localStorage.getItem('accessToken') == null){
            localStorage.setItem('accessToken', response?.data?.access_token);
            localStorage.setItem('ownerEmail', response?.data?.email);
            localStorage.setItem('userId', response?.data?.userId);
            localStorage.setItem('storeExists', 'false');
          }
          
          showToast('Subscription successful!', 'success');
          navigate('/app/dashboard');
          setLoading(false);
        } else {
          setLoading(false);
          showToast(response.error.message, 'error');
        }
      }
    };

  return (
    <form onSubmit={handleSubmit} className='subsPayForm'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <div className='card-element'>
            <CardElement />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            className='w-130'
            variant="primary"
            type="submit" 
            disabled={!stripe}
          >
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const SubscriptionCheckoutComponent = ({planId, setSubscriptionPayModalOpen}) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
        planId={planId} 
        setSubscriptionPayModalOpen={setSubscriptionPayModalOpen}
      />
    </Elements>
  );
};

export default SubscriptionCheckoutComponent;
