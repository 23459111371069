import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Button, FormControl, Grid2 } from '@mui/material';
import { FormLabel, RadioGroup, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useGlobalContext } from '../../Context/GlobalContext';
import { shopBranchPostApi } from '../../API/ShopAndShopBranch/ShopApi';
import { loadGoogleMapsApi } from '../../loadGoogleMapsApi';
import "./ShopBranchComponent.css";

const ShopBranchComponent = () => {

    const [shopBranchName, setShopBranchName] = useState('');
    const [shopBranchNameError, setShopBranchNameError] = useState(false);
    const [shopBranchNameHelperText, setShopBranchNameHelperText] = useState('');

    const [shopBranchEmail, setShopBranchEmail] = useState('');
    const [shopBranchEmailError, setShopBranchEmailError] = useState(false);
    const [shopBranchEmailHelperText, setShopBranchEmailHelperText] = useState('');

    const [shopBranchContact, setShopBranchContact] = useState('');
    const [shopBranchContactError, setShopBranchContactError] = useState(false);
    const [shopBranchContactHelperText, setShopBranchContactHelperText] = useState('');

    const [ecomUrl, setEcomUrl] = useState('');
    const [ecomUrlError, setEcomUrlError] = useState(false);
    const [ecomUrlHelperText, setEcomUrlHelperText] = useState('');

    const [isOnline, setIsOnline] = useState('');
    const [isOnlineError, setIsOnlineError] = useState(false);
    const [isOnlineHelperText, setIsOnlineHelperText] = useState('');

    const [shopLocation, setShopLocation] = useState([]);
    const [shopLocationError, setShopLocationError] = useState(false);
    const [shopLocationHelperText, setShopLocationHelperText] = useState('');

    const { setLoading, showToast } = useGlobalContext();
    const locationFieldRef = useRef(null);
    const navigate = useNavigate();
    const mapRef = useRef(null);

    const validate = (name) => {
        const nameRegex = /^[a-zA-Z0-9-' ]+$/;
        ;
        return nameRegex.test(name);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    useEffect(() => {
        loadGoogleMapsApi(process.env.REACT_APP_GOOGLE_API_KEY, () => {
            if (window.google && window.google.maps && window.google.maps.places) {
                const input = locationFieldRef.current;
                const autocompleteInstance = new window.google.maps.places.Autocomplete(input);
                autocompleteInstance.setFields(['formatted_address', 'geometry']);
                autocompleteInstance.addListener('place_changed', () => onPlaceChanged(autocompleteInstance));
            } else {
                console.error('Google Maps API is not available.');
            }
        });
    }, []);

    const handleLocationDelete = (locationToDelete) => () => {
        setShopLocation((currentLocations) =>
            currentLocations.filter((location) => location !== locationToDelete)
        );
    };

    const mapOptions = {
        fullscreenControl: true,
    };

    const handleMapLoad = useCallback((map) => {
        mapRef.current = map;

        // Customize the fullscreen control
        const fullscreenControl = map.controls[window.google.maps.ControlPosition.RIGHT_TOP].getArray()[0];
        if (fullscreenControl) {
            fullscreenControl.style.width = '140px';
            fullscreenControl.style.height = '140px';

            const img = fullscreenControl.querySelector('img');
            if (img) {
                img.style.width = '100%';
                img.style.height = '100%';
            }
        }
    }, []);

    const onPlaceChanged = (autocomplete) => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
            const newLocation = {
                formattedAddress: place.formatted_address,
                coords: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
            };
            setShopLocation([newLocation]);
            setShopLocationError(false);
            setShopLocationHelperText('');
        } else {
            setShopLocationError(true);
            setShopLocationHelperText('Please select a valid location.');
        }
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            let valid = true;

            if (!validate(shopBranchName)) {
                setShopBranchNameError(true);
                setShopBranchNameHelperText('Please enter your store name by alphabet and digits');
                valid = false;
                setLoading(false);
            } else {
                setShopBranchNameError(false);
                setShopBranchNameHelperText('');
            }
            if (!validateEmail(shopBranchEmail)) {
                setShopBranchEmailError(true);
                setShopBranchEmailHelperText('Please enter a valid shop email address');
                valid = false;
                setLoading(false);
            } else {
                setShopBranchEmailError(false);
                setShopBranchEmailHelperText('');
            }
            if (!shopBranchContact) {
                setShopBranchContactError(true);
                setShopBranchContactHelperText('Please choose the which shopsource you are using');
                valid = false;
                setLoading(false);
            } else {
                setShopBranchContactError(false);
                setShopBranchContactHelperText('');
            }
            if (!ecomUrl) {
                setEcomUrlError(true);
                setEcomUrlHelperText('Please choose shop Ecom Url');
                valid = false;
                setLoading(false);
            } else {
                setEcomUrlError(false);
                setEcomUrlHelperText('');
            }
            if (!isOnline) {
                setIsOnlineError(true);
                setIsOnlineHelperText('Please select if the shop is online or not');
                setLoading(false);
                valid = false;
            } else {
                setIsOnlineError(false);
                setIsOnlineHelperText('');
            }
            if (!shopLocation) {
                setShopLocationError(true);
                setShopLocationHelperText('Please enter your shop location');
                setLoading(false);
                valid = false;
            } else {
                setShopLocationError(false);
                setShopLocationHelperText('');
            }
            if (valid) {
                let shopBranchData = {
                    userId: localStorage.getItem("userId"),
                    store_id: localStorage.getItem("storeId"),
                    branch_name: shopBranchName,
                    branch_email: shopBranchEmail,
                    branch_contact: shopBranchContact,
                    ecommerce_url: ecomUrl,
                    address: shopLocation[0]?.formattedAddress,
                    store_geo_location: {
                        type: "Point",
                        coordinates: [shopLocation[0]?.coords.lat, shopLocation[0]?.coords.lng]
                    },
                    is_online: isOnline,
                }

                const response = await shopBranchPostApi(shopBranchData);
                if (response.status === 200) {
                    showToast('Branch has been created successfully', 'success');
                    setLoading(false);
                    navigate("/app/shop-branch-list")
                } else {
                    showToast('Branch has not been created', 'error');
                }
            }
        } catch (error) {
            setLoading(false);
            showToast('Branch has not been created ', 'error');
        }
    };

    return (
        <div className='shopBranchContainer'>
            <Grid container spacing={1} >
                <Grid item md={7} className='shopBranchGridContainer'>
                    <div className='shopBranchTitle'>
                        <p>Tell Us About Your Store Branch Details </p>
                    </div>
                    <form noValidate className="shopBranchForm" onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            placeholder='Please enter your store branch name'
                            id="shopBranchName"
                            label="Store Branch Name*"
                            name="shopBranchName"
                            autoComplete="shopBranchName"
                            autoFocus
                            value={shopBranchName}
                            onChange={(e) => {
                                setShopBranchName(e.target.value);
                                setShopBranchNameError(false);
                                setShopBranchNameHelperText("");
                            }}
                            error={shopBranchNameError}
                            helperText={shopBranchNameHelperText}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            placeholder='Please enter your store Branch email'
                            id="shopBranchEmail"
                            label="Store Branch Email*"
                            name="shopBranchEmail"
                            autoComplete="shopBranchEmail"
                            autoFocus
                            value={shopBranchEmail}
                            onChange={(e) => {
                                setShopBranchEmail(e.target.value);
                                setShopBranchEmailError(false);
                                setShopBranchEmailHelperText("");
                            }}
                            error={shopBranchEmailError}
                            helperText={shopBranchEmailHelperText}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            placeholder='Please enter your store branch contact'
                            id="shopBranchContact"
                            label="Store Branch Contact*"
                            name="shopBranchContact"
                            autoComplete="shopBranchContact"
                            autoFocus
                            value={shopBranchContact}
                            onChange={(e) => {
                                setShopBranchContact(e.target.value);
                                setShopBranchContactError(false);
                                setShopBranchContactHelperText("");
                            }}
                            error={shopBranchContactError}
                            helperText={shopBranchContactHelperText}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="shopLocation"
                            label="Add location by store address"
                            placeholder="Add location by address"
                            name="shopLocation"
                            inputRef={locationFieldRef}
                            error={shopLocationError}
                            helperText={shopLocationHelperText}
                        />
                        <div className="shopLocation">
                            {shopLocation.map((sLocation, index) => (
                                <div className="shopLocationChip" key={index}>
                                    <div className="shopMapAndLocation">
                                        <GoogleMap
                                            center={sLocation.coords}
                                            zoom={15}
                                            options={mapOptions}
                                            onLoad={handleMapLoad}
                                            mapContainerStyle={{ height: "80px", width: "130px" }}
                                        >
                                            <MarkerF position={sLocation.coords} />
                                        </GoogleMap>
                                        <div>{sLocation.formattedAddress}</div>
                                    </div>
                                    <div className="addressCrossLocation" onClick={handleLocationDelete(sLocation)}>
                                        <img src={process.env.REACT_APP_URL + 'Cross-1171276068.svg'} alt="cross" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="shopEcomUrl"
                            label="Store Ecom URL"
                            placeholder='Enter store ecom url'
                            name="shopecomUrl"
                            autoComplete="shopEcomUrl"
                            autoFocus
                            value={ecomUrl}
                            onChange={(e) => {
                                setEcomUrl(e.target.value);
                                setEcomUrlError(false);
                                setEcomUrlHelperText("");
                            }}
                            error={ecomUrlError}
                            helperText={ecomUrlHelperText}
                        />
                        <FormControl error={isOnlineError} component="fieldset" margin="normal">
                            <FormLabel className='isOnlineLable'>Is Online?</FormLabel>
                            <RadioGroup
                                row
                                value={isOnline}
                                onChange={(e) => {
                                    setIsOnline(e.target.value);
                                    setIsOnlineError(false);
                                    setIsOnlineHelperText('');
                                }}
                            >
                                <Grid2 container spacing={2} className='radioOptionContainer'>
                                    <Grid2 item xs={12} sm={4} md={3} className="radioOption">
                                        <Radio value="true" className='onlineRadioButton' />
                                        <span>Yes</span>
                                    </Grid2>
                                    <Grid2 item xs={12} sm={4} md={3} className="radioOption">
                                        <Radio value="false" className='onlineRadioButton' />
                                        <span>No</span>
                                    </Grid2>
                                </Grid2>
                            </RadioGroup>
                            {isOnlineError && <FormHelperText>{isOnlineHelperText}</FormHelperText>}
                        </FormControl>
                        <div className='shopBranchSubmitButtonContainer'>
                            <Button
                                type="submit"
                                variant="primary"
                                className="shopBranchSubmitButton"
                            >
                                Submit
                            </Button>
                        </div>

                    </form>
                </Grid>
                <Grid item md={5} className='shopBranchImage' >
                    <div>
                        <img src={process.env.REACT_APP_URL + 'Inbox.png'} alt='InboxIcon' />
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default ShopBranchComponent
