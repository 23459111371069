import React, {useState, useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useGlobalContext } from '../../Context/GlobalContext';
import  './EditStoreTypeStyles.css';
import {postApi}from '../../apiClient';

const EditStoreTypeComponent = ({
  editStoreType,
  setEditStoreType,
  editStoreTypeError,
  editStoreTypeHelperText,
  editBothStoreType,
  setEditBothStoreType,
  editBothStoreTypeError,
  editBothStoreTypeHelperText,
  setEditStoreTypeError,
  setEditStoreTypeHelperText,
  setEditBothStoreTypeError,
  setEditBothStoreTypeHelperText,
  onboardingStoreDetailsData
})=> {
  //const { } = useGlobalContext();
  // if(haveRetailStoreLocation){
  //   setEditStoreType(haveRetailStoreLocation)
  // }
  const [storeLocationForEdit, setStoreLocationForEdit] = useState([]);
  const { setLoading, showToast} = useGlobalContext();

  useEffect(()=>{
    if(onboardingStoreDetailsData){
      const lastUpdateStoreType = onboardingStoreDetailsData.isStoreRetail || "";
      const lastUpdatePrimaryObjective =  onboardingStoreDetailsData.idealOutcomeWithPushsend;
      const lastUpdateLocationList =  onboardingStoreDetailsData.locations;
      setEditStoreType(lastUpdateStoreType);
      setEditBothStoreType(lastUpdatePrimaryObjective);
      setStoreLocationForEdit(lastUpdateLocationList);
    }
  },[onboardingStoreDetailsData])

  const handleBothQuestionChange = (event) => {
    setEditBothStoreType(event.target.value);
  }

  const handleEditStoreType = async () => {
    try{
     // e.preventDefault();
      setLoading(true);
      let valid = true;
      
      if (!editStoreType) {
        setEditStoreTypeError(true);
        setEditStoreTypeHelperText('Please choose the store type');
        valid = false;
        setLoading(false);
      } else {
        setEditStoreTypeError(false);
        setEditStoreTypeHelperText('');
      }
      
      if (editBothStoreType === '' && editStoreType === "Both") {
        setEditBothStoreTypeError(true);
        setEditBothStoreTypeHelperText('Please choose your primary objective with PushSend Ai');
        valid = false;
        setLoading(false);
      } else {
        setEditBothStoreTypeError(false);
        setEditBothStoreTypeHelperText('');
      }
     
      if(valid){
        let editStoreTypeData = {
          storeId:localStorage.getItem("storeId"),
          locations: editStoreType === 'Online Stores'? [] : storeLocationForEdit,
          isStoreRetail:editStoreType,
          idealOutcomeWithPushsend: editStoreType === 'Both'? editBothStoreType : ''
        }
        // console.log(editStoreTypeData);
        
        let response = await postApi('api/update-store-details',editStoreTypeData)
        const { message} = response.data;
        if (response.status === 200 && message) {
          showToast(message, 'success');
          setLoading(false);
        }
        
      }

    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  }

  return (        
      <div className='editStoreTypeFormContainer'>
        <div className='editStoreFormController'>
          <form noValidate className="ml-4"> 
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}
              <RadioGroup aria-label="editStoreType" className='gap-[20px]' name="editStoreType" value={editStoreType} row>
                <FormControlLabel
                  value="Physical Stores"
                  control={
                    <div className={` ${editStoreType === 'Physical Stores' ? 'storeTypeOptionSelected' : 'storeTypeOption'}`} onClick={() => setEditStoreType('Physical Stores')}>
                      <div  className="flex items-center mt-4">
                        <img
                          src={editStoreType === 'Physical Stores' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                          alt="Female"
                          className="editStoreTypeCircleImg"
                        />
                        <p className="editStoreTypeName">Physical Stores</p>
                      </div>
                    </div>
                  }
                  // error={editStoreTypeError}
                  // helperText={editStoreTypeHelperText}
                />
                <FormControlLabel
                  value="Online Stores"
                  control={
                    <div className={` ${editStoreType === 'Online Stores' ? 'storeTypeOptionSelected' : 'storeTypeOption'}`}   onClick={() => setEditStoreType('Online Stores')}>
                      <div  className="flex items-center mt-4">
                        <img
                          src={editStoreType === 'Online Stores' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                          alt="Female"
                          className="editStoreTypeCircleImg"
                        />
                        <p className="editStoreTypeName">Online Stores</p>
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  value="Both"
                  control={
                    <div className={` ${editStoreType === 'Both' ? 'storeTypeOptionSelected' : 'storeTypeOption'}`}   onClick={() => setEditStoreType('Both')}>
                      <div  className="flex items-center mt-4">
                        <img
                          src={editStoreType === 'Both' ? process.env.REACT_APP_URL+'Radio-select.svg' : process.env.REACT_APP_URL+'Radio-unselect.svg'}
                          alt="Female"
                          className="editStoreTypeCircleImg"
                        />
                        <p className="editStoreTypeName">Both</p>
                      </div>
                    </div>
                  }
                />
                     {/* Display helper text if there's an error */}
                      {editStoreTypeError && (
                     <FormHelperText className='text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans'>
                       {editStoreTypeHelperText}
                      </FormHelperText>
                       )}

              </RadioGroup>
              {
                editStoreTypeError === true ? <p className='text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans'>{editStoreTypeHelperText}</p>:null
              }
            </FormControl>
            {editStoreType === 'Both' && (
              <div className='mt-6'>
              <p className='editStoreTypeQuestion'>What is your primary objective with PushSend Ai?</p>
              <FormControl component="fieldset">
                <RadioGroup value={editBothStoreType} onChange={handleBothQuestionChange}>
                  <FormControlLabel
                    control={<Radio  sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 18, // Control the size of the Radio button
                        color:'#646464'
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: 'rgb(150 98 255)', // Change color of the selected Radio button
                      },
                    }}/>}
                    label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more foot traffic and sales to our physical locations</Typography>}
                    value="Driving more foot traffic and sales to our physical locations"
                    
                  />
                  <FormControlLabel
                    control={<Radio sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 18, // Control the size of the Radio button
                        color:'#646464'
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: 'rgb(150 98 255)', // Change color of the selected Radio button
                      },
                    }}/>}
                    label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more traffic and sales to our online store</Typography>}
                    value="Driving more traffic and sales to our online store"
                  />
                  <FormControlLabel
                    control={<Radio sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: 18, // Control the size of the Radio button
                        color:'#646464'
                      },
                      '&.Mui-checked .MuiSvgIcon-root': {
                        color: 'rgb(150 98 255)', // Change color of the selected Radio button
                      },
                    }}/>}
                    label={<Typography variant="body2" sx={{marginBottom: '15px', fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43', color:'#646464'}}>Driving more sales to both</Typography>}
                    value="Driving more sales to both"
                  />
                </RadioGroup>
                {editBothStoreTypeError && (
                  <FormHelperText className="text-[#d32f2f] text-[12px] not-italic font-normal leading-normal font-dm-sans">
                    {editBothStoreTypeHelperText}
                  </FormHelperText>
                )}
              </FormControl>
              </div>
            )}
          </form>
        </div>
        <div className='editStoreTypeButton'>
          <Button
            onClick={()=> handleEditStoreType()}
            variant="primary"
            fullWidth
          >
            Update
            <img
              src={process.env.REACT_APP_URL+"next-long-arrow-right.svg"}
              alt="next"
            />
          </Button>
        </div>
      </div>
      
  )
}

 export default EditStoreTypeComponent
