import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, Button } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
// import avatarImage from '../Image/walpaper.jpg';
import './ViewConversationStyles.css';
import { viewConversationCustomerListGetApi, viewConversationMessageGetApi } from '../../API/ViewConversation/viewConversation';
import { useGlobalContext } from '../../Context/GlobalContext';

const ViewConversationComponent = ({ promotion, setIsViewConversationVisible, setIsViewPromotionList }) => {
  const [selectedTab, setSelectedTab] = useState("email");
  const [customerDetails, setCustomerDetails] = useState([]);
  const promotionId = promotion.promotionId;
  const [messageDetails, setMessageDetails] = useState("");
  const [activeCustomerId, setActiveCustomerId] = useState(null);
  const [emailGenerated, setEmailGenerated] = useState(false);
  const [smsGenerated, setSmsGenerated] = useState(false);
  const [whatsappGenerated, setWhatsappGenerated] = useState(false);
  const { setLoading } = useGlobalContext();

  useEffect(() => {
    getViewConversationData();
  }, []);


  const getViewConversationData = async () => {
    setLoading(true);
    let response = await viewConversationCustomerListGetApi(promotionId);
    if (response.status === 200) {
      setLoading(false);
      setCustomerDetails(response.data.customerDetails);
      // Automatically click the first customer button if exists
      if (response.data.customerDetails.length > 0) {
        const firstCustomerId = response.data.customerDetails[0].customerId;
        setActiveCustomerId(firstCustomerId); // Set the active customer ID
        getViewConversationMessageData(firstCustomerId);
      }
    }
    else {
      setLoading(false);
      console.log("API fetching error");
    }
  }

  const getViewConversationMessageData = async (customerId) => {
    setLoading(true);
    try {
      let response = await viewConversationMessageGetApi(promotionId, customerId);
      if (response.status === 200) {
        setLoading(false);
        // setEmailGenerated(response.data.promotionDetail[0].emailGenerated)
        setSmsGenerated(response.data.promotionDetail[0].smsGenerated);
        setWhatsappGenerated(response.data.promotionDetail[0].whatsappGenerated);
        setMessageDetails(response.data.promotionDetail[0]);

        // // Automatically select the email tab if email is generated
        // if (emailGenerated) {
        //   setSelectedTab('email'); // Assuming you have a state for selectedTab
        // }
      } else {
        console.log("API fetching error");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching conversation messages:", error);
    }
  };



  const handleCustomerNameClicked = (customerId) => {
    setActiveCustomerId(customerId);
    getViewConversationMessageData(customerId);
  };

  const handleButtonClick = (tab) => {
    setSelectedTab(tab);
  };
  const viewConeversationBack = () => {
    setIsViewConversationVisible(false);
    setIsViewPromotionList(true);
  }

  // // Format content by replacing newlines with <br /> tags
  // const formattedContent = messageDetails?.generatedEmail?.email_content?.split('\n').map((line, index) => (
  //   <span key={index}>
  //     {line}
  //     <br />
  //     <br />
  //   </span>
  // ));

  return (
    <div className='conversationContainer'>
      <div className='viewConversationBackButton'>
        <img src={process.env.REACT_APP_URL + 'back-long-arrow-.svg'} alt='backIcon' onClick={viewConeversationBack} className='cursor-pointer' />
        <p className='promotionNameTitle'>{promotion.promotionName}</p>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2} mt={4} mx={1} className='viewConversationGridContainer'>
            {customerDetails.map((user, index) => (
              <Grid item xs={12} key={index} className={`${activeCustomerId === user.customerId ? 'viewConversationBoxActive' : 'viewConversationBoxInactive'}`}>
                <div className={`${activeCustomerId === user.customerId ? 'userImageBackgroundActive' : 'userImageBackgroundInactive'}`}>
                  <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
                </div>
                <button onClick={() => handleCustomerNameClicked(user.customerId)}
                  className={`${activeCustomerId === user.customerId ? 'customerActiveButton' : 'customerInactiveButton'}`}
                >{user.firstName}  {user.lastName}</button>
              </Grid>
            ))}
            {/* <Grid item xs={12} className='viewConversationBoxInactive'>
              <div className='userImageBackgroundInactive'>
                <img src={process.env.REACT_APP_URL + 'user.svg'} className='userImages' />
              </div>
              <button
                className='customerInactiveButton'
              >SayanDip Sar</button>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={9} mt={4} >
          <Grid mx={1}>
            <Grid container spacing={1} className='ml-5'>
              {promotion?.deliveryPlatform?.map((platform, index) =>
                <Grid item key={index} className='gridTabButtonContainer'>
                  <Button
                    onClick={() => {
                      if (platform === 'sms' && !smsGenerated) {
                        return; // Prevent click if sms is not generated
                      }
                      if (platform === 'whatsapp' && !whatsappGenerated) {
                        return; // Prevent click if whatsapp is not generated
                      }
                      handleButtonClick(platform);
                    }}
                    className={`${selectedTab === platform ? 'tabButtonSelected' : 'tabButton'} ${(!smsGenerated && platform === 'sms') || (!whatsappGenerated && platform === 'whatsapp') ? 'tabButton' : 'tabButtonSelected'}`}
                    disabled={!smsGenerated && platform === 'sms' || !whatsappGenerated && platform === 'whatsapp'}
                  >
                    <p className={selectedTab === platform ? 'tabButtonTextSelected' : 'tabButtonText'}>{platform}</p>
                    <hr className='text-[#000]' />
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} className='gridMessageBoxContainer'>
              <Box component="section" className='contentBoxBorder'>
                <div>
                  <div className='generatedContentHearder'>
                    {
                      selectedTab === "sms" ?
                        <img src={process.env.REACT_APP_URL + 'message-icon.png'} className='w-[24px] h-[25px]' />
                        : selectedTab === "email" ?
                          <img src={process.env.REACT_APP_URL + 'message-icon.png'} className='w-[24px] h-[25px]' />
                          :
                          <img src={process.env.REACT_APP_URL + 'whatsappIcon.jpg'} className='w-[24px] h-[25px]' />
                    }
                    <p className='generatedContentTitle'>{selectedTab}</p>
                  </div>
                  <hr className='horizonDivider' />
                  {selectedTab === 'sms' &&
                    <div className='messageDetailsContainer'> SMS This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                    </div>
                  }
                  {selectedTab === 'email' && messageDetails && messageDetails.generatedEmail &&
                    <div className='messageDetailsContainer'>
                      <div>Subject-{messageDetails.generatedEmail.subject}</div><br />
                      <div>Image-{messageDetails.generatedEmail.img_bnr_txt}</div><br />
                      <div>Heading1-{messageDetails.generatedEmail.heading1}</div><br />
                      <div>Heading2-{messageDetails.generatedEmail.heading2}</div><br />
                      <div>Content1-{messageDetails.generatedEmail.content1}</div><br />
                      <div>Content2-{messageDetails.generatedEmail.content2}</div><br />
                      <div>End_Note-{messageDetails.generatedEmail.end_note}</div><br />
                    </div>
                  }
                  {selectedTab === 'whatsapp' &&
                    <div className='messageDetailsContainer'> Whatsapp This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                      This Box renders as an HTML section element. This Box renders as an HTML section element.
                    </div>
                  }
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </div >

  )
}

export default ViewConversationComponent
