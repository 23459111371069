import React,{useEffect,useState} from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const MonthSalesLineCharts = () => {
    const [lineChartWidth, setLineChartWidth] = useState(window.innerWidth < 600 ? "250" : 300);
    const [lineChartHeight, setLIneChartHeight] = useState(window.innerWidth < 600 ? "250" : 300);

    const handleResize = () => {
        setLineChartWidth(window.innerWidth < 600 ? "250" : 300);
        setLIneChartHeight(window.innerWidth < 600 ? "250" : 300);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <LineChart
                xAxis={[{
                    data: [2, 3, 4, 5, 6, 7, 8, 9, 10],
                    disableLine: true,
                    disableTicks: true,
                }]}
                leftAxis={null}
                series={[{
                    data: [2, 3.5, 4.5, 2.5, 5, 6, 7, 8, 9,],
                    area: true,
                    // areaColor: '#711FFF',
                    color: '#711FFF',
                    lineColor: '#28006D',
                    showMark: false,
                }]}
                width={lineChartWidth}
                height={lineChartHeight}
            />

        </div>
    )
}

export default MonthSalesLineCharts
