import React from 'react';
import { Grid, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import "./CustomerProfie.css";




const CustomerProfile = () => {
    return (
        <>
            <div className='customerProfileContainer'>
                <Grid container>
                    <Grid item md={3} className='customerNameCotainer'>
                        <Grid container spacing={3} mt={1} mx={2} className='customerNameList'>
                            <Grid item display="flex" justifyContent="center" alignItems="center" >
                                <Avatar className='profileAvatar'>JW</Avatar>
                                <button className='profileName'>Jessica Walter</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>TJ</Avatar>
                                <button className='profileName'>Tom Johnson</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>BM</Avatar>
                                <button className='profileName'>Bruce Miller</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>TW</Avatar>
                                <button className='profileName'>Timothy Walker</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center" >
                                <Avatar className='profileAvatar'>JS</Avatar>
                                <button className='profileName'>Janney Smuck</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>PW</Avatar>
                                <button className='profileName'>Pam Walter</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>JW</Avatar>
                                <button className='profileName'>Jannet Wong</button>
                            </Grid>
                            <Grid item display="flex" justifyContent="center" alignItems="center"  >
                                <Avatar className='profileAvatar'>LP</Avatar>
                                <button className='profileName'>Logan Paul</button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={9} mt={1}>
                        <Grid className='customerFullDetails'>
                            <Card className='customerFullDetailsWrapper'>
                                <Box sx={{ p: 3 }}
                                    className="customerHeadingDetails"
                                >
                                    <Grid container>
                                        <Grid item md={9} mb={1}>
                                            <div className='customerName'>Jessica Walter</div>
                                            <Stack direction="row" spacing={1} >
                                                <img
                                                    src={process.env.REACT_APP_URL + 'emailIcon.svg'} alt='EmailIcon'
                                                />
                                                <div className='customerEmail'>jessicawalter@gmail.com</div>
                                            </Stack >
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='membershipInfoContainer'>
                                                <Stack direction="row" spacing={0.5} className='membershipStatusStack'>
                                                    <img
                                                        src={process.env.REACT_APP_URL + 'MembarShip.png'} alt='MembarShipIcon'
                                                    />
                                                    <div className='membarshipStatus'>Gold Membar</div>
                                                </Stack>
                                                <div className='customerSince'>Customer Since 2019</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 3 }} >
                                    <div className='informationTitle'>
                                        Customer Information
                                    </div>
                                    <div className="customerInformationContainer">
                                        <Stack direction="row" spacing={0.5} className="locationDetailsStack">
                                            <LocationOnOutlinedIcon fontSize='small' style={{ color: "#4F6095" }} />
                                            <div className='customerInformation'>New York,USA</div>
                                        </Stack>
                                        <Stack direction="row" spacing={0.5} className="birthDateDetailsStack">
                                            <img
                                                src={process.env.REACT_APP_URL + 'birthDate.png'} alt='BirthDateIcon'
                                            />
                                            <div className='customerInformation'>April 22,1996</div>
                                        </Stack>
                                        <Stack direction="row" spacing={0.5} className="genderStack">
                                            <img
                                                src={process.env.REACT_APP_URL + 'gender-symbol .png'} alt='GenderIcon'
                                            />
                                            <div className='customerInformation'>Female</div>
                                        </Stack>
                                    </div>
                                </Box>
                                <Divider variant="middle" className='sectionDivider' />
                                <Box sx={{ p: 3 }}>
                                    <div className='overviewTitle'>
                                        Customer Overview
                                    </div>
                                    <Typography variant="body2" sx={{ color: '#3E3C4D', fontSize: '12px', fontFamily: "DM Sans" }} mt={1}>
                                        Jane Doe is a loyal and engaged customer of our Shopify store, having made consistent purchases over the
                                        past five years.
                                        As a Gold Member of our loyalty program, Jane is known for her preference for eco-friendly products and
                                        a keen interest in the latest fashion trends. With an average order value of $150, she frequently takes
                                        advantage of seasonal sales and is highly responsive to personalized marketing campaigns. Jane often
                                        engages with our brand through social media channels, sharing her purchases and providing feedback
                                        that has been invaluable in shaping our product offerings.
                                    </Typography>
                                </Box>
                                <Divider variant="middle" className='sectionDivider' />
                                <Box sx={{ p: 3 }}>
                                    <div className='historyTitle'>
                                        Purchase History
                                    </div>
                                    <Grid container spacing={1} mt={0.5}>
                                        <Grid item md={6} >
                                            <Grid>
                                                <Stack
                                                    spacing={{ xs: 1, sm: 1 }}
                                                    direction="row"
                                                    useFlexGap
                                                    sx={{ flexWrap: 'wrap' }}
                                                >
                                                    <Stack direction="row" spacing={1} className='ordersSummaryCotainer'>
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'Order.png'} alt='OrderIcon'
                                                        />
                                                        <div className='totalOrder' >Total Order:45</div>
                                                    </Stack>
                                                    <Stack direction="row" spacing={1} className='spendSummaryCotainer' >
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'Money.png'} alt='MoneyIcon'
                                                        />
                                                        <div className='totalSpend' >Total Spend $6,545</div>
                                                    </Stack>

                                                    <Stack direction="row" className='frequencySummaryCotainer' spacing={1}>
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'Calender.png'} alt='CalenderIcon' className='CalenderImage'
                                                        />
                                                        <div className='frequencyInfo' >Average Order Frequency:Every 2 Months</div>
                                                    </Stack>
                                                    <Stack direction="row" spacing={1} className='averageOrderSummaryCotainer'>
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'averageOrder.png'} alt='AverageOrderIcon'
                                                        />
                                                        <div className='priceInfo' >Average Order Price $450</div>
                                                    </Stack>
                                                </Stack>
                                                <Stack spacing={1} mt={1}>
                                                    <div className='favouriteBrandDetails'>
                                                        <Stack direction="row" spacing={1}>

                                                            <div className='favouriteBandTitle'>Favorite Brand</div>
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'favoriteBrandIcon.png'} alt='FavoriteBrandIcon'
                                                            />
                                                        </Stack>
                                                        <Stack direction="row" spacing={2} mt={1} className="brandLogoContainer">
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'sestain.png'} alt='BrandLogo' className='BrandLogo'
                                                            />
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'ecoelegance.png'} alt='BrandLogo' className='BrandLogo'
                                                            />
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'greenThreads.png'} alt='BrandLogo' className='BrandLogo'
                                                            />
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'sestain.png'} alt='BrandLogo' className='BrandLogo'
                                                            />
                                                            <img
                                                                src={process.env.REACT_APP_URL + 'greenThreads2.png'} alt='BrandLogo' className='BrandLogo'
                                                            />
                                                        </Stack>
                                                    </div>
                                                    <div className='purchasedCategoriesContainer'>
                                                        <div className='categoriesTitle'>Most Purchased Categories</div>
                                                        <Stack direction="row" spacing={1} mt={1}>
                                                            <Box
                                                                className="firstProgressWrapper"
                                                            >
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={100}
                                                                    size={60}
                                                                    thickness={4}
                                                                    className="firstCircularProgress"
                                                                />
                                                                <Box
                                                                    className="firstProgressLabelOverlay"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        className="firstPercentageLabel"
                                                                    >
                                                                        {`60%`}
                                                                    </Typography>
                                                                </Box>
                                                                <div className='categoryLabel'>Woman's Fashion</div>
                                                            </Box>
                                                            <Box className="secondProgressWrapper">
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={100}
                                                                    size={60}
                                                                    thickness={4}
                                                                    className="secondCircularProgress"
                                                                />
                                                                <Box
                                                                    className="secondProgressLabelOverlay"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        className="secondPercentageLabel"
                                                                    >
                                                                        {`25%`}
                                                                    </Typography>
                                                                </Box>
                                                                <div className='categoryLabel'>Eco-Friendly Household</div>
                                                            </Box>
                                                            <Box className="thirdProgressWrapper">
                                                                <CircularProgress
                                                                    variant="determinate"
                                                                    value={100}
                                                                    size={60}
                                                                    thickness={4}
                                                                    className="thirdCircularProgress"
                                                                />
                                                                <Box
                                                                    className="thirdProgressLabelOverlay"
                                                                >
                                                                    <Typography
                                                                        variant="caption"
                                                                        component="div"
                                                                        className="thirdPercentageLabel"
                                                                    >
                                                                        {`15%`}
                                                                    </Typography>
                                                                </Box>
                                                                <div className='categoryLabel'>Accessories</div>
                                                            </Box>
                                                        </Stack>
                                                    </div>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Grid className='purchasesDetails'>
                                                <div className='purchaseTitle'>Resent Purchases </div>
                                                <Stack spacing={2} mt={1}>
                                                    <Stack spacing={2} direction="row">
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'cottonDress.png'} alt='CottonDressImage'
                                                        />
                                                        <Stack spacing={0.5}>
                                                            <div className='purchaseDate'>August 2024</div>
                                                            <div className='purchaseDescription'>Organic cotton Dress</div>
                                                            <div className='purchaseValue'>$80</div>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack spacing={2} direction="row">
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'materiaTagBag.png'} alt='MaterialTagImage'
                                                        />
                                                        <Stack spacing={0.5}>
                                                            <div className='purchaseDate' >June 2024</div>
                                                            <div className='purchaseDescription'>Recycle Material Tag Bag </div>
                                                            <div className='purchaseValue'>$45</div>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack spacing={2} direction="row">
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'BambooToothBrush.png'} alt='BambooToothBrushImage'
                                                        />
                                                        <Stack spacing={0.5}>
                                                            <div className='purchaseDate'>May 2024</div>
                                                            <div className='purchaseDescription'>Bamboo Toothbrush Set</div>
                                                            <div className='purchaseValue'>$20</div>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider variant="middle" className='sectionDivider' />
                                <Box sx={{ p: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={8}>
                                            <Grid className='analysisTitle'>
                                                Predictive Analysis
                                            </Grid>
                                            <Box mt={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer1'>
                                                            <Stack direction="row">
                                                                <div className='purchasePercentage'>75%</div>
                                                                <div className='nextPurchaseLabel'>Next Purchase </div>
                                                            </Stack>
                                                            <p className='customerOverview'>likelihood of Jane buying from our fall collection, especially eco-friendly items</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer'>
                                                            <Stack direction="row" >
                                                                <div className='purchasePercentage'>80%</div>
                                                                <div className='nextPurchaseLabel'>Subscription Interest</div>
                                                            </Stack>
                                                            <p className='customerOverview'>chance Jane will join our subscription service for curated eco-friendly bundles</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer'>
                                                            <Stack direction="row">
                                                                <div className='purchasePercentage'>30%</div>
                                                                <div className='nextPurchaseLabel'>Loyalty Engagement</div>
                                                            </Stack>
                                                            <p className='customerOverview'>increase in purchase frequency expected with exclusive offers or bonus points
                                                            </p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer1'>
                                                            <Stack direction="row">
                                                                <div className='purchasePercentage'>50%</div>
                                                                <div className='nextPurchaseLabel'>Seasonal Spending</div>
                                                            </Stack>
                                                            <p className='customerOverview'>higher spend anticipated during seasonal launches with personalized promotions.</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer1'>
                                                            <Stack direction="row" >
                                                                <div className='purchasePercentage'>20%</div>
                                                                <div className='nextPurchaseLabel'>Upsell Opportunity</div>
                                                            </Stack>
                                                            <p className='customerOverview'>Recommending complementary accessories could boost her order value</p>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Stack className='customerAnalysisContainer'>
                                                            <Stack direction="row">
                                                                <div className='purchasePercentage'>10%</div>
                                                                <div className='nextPurchaseLabel'>Social Influence</div>
                                                            </Stack>
                                                            <p className='customerOverview'>boost in customer referrals if Jane is incentivized to share her purchases on social media.</p>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div className='highlightTitle'>Highlight</div>
                                            <Stack spacing={2} mt={2}>
                                                <button className='highlightButton'>Weeekend Shopper</button>
                                                <button className='highlightButton'>Night Owl</button>
                                                <button className='highlightButton'>Favorite Brand</button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default CustomerProfile
