import React, { useEffect, useState } from 'react';
import { TextField, Modal, Button, Box, Typography, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, FormLabel, ListItemText, FormHelperText } from '@mui/material';
import { Checkbox, FormGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGlobalContext } from '../../Context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { getPromotionDetailsApi, getListSelectOptionsApi, getPromotionCategoryListApi, editPromotionApi } from '../../API/createPromotion/createPromotion';
import "./PromotionEditModalStyles.css";

const PromotionCalenderEditModal = ({ isVisible, evenId, onClose, getPromotionLists }) => {

  const [editPromotionName, setEditPromotionName] = useState('');
  const [editPromotionNameError, setEditPromotionNameError] = useState(false);
  const [editPromotionNameHelperText, setEditPromotionNameHelperText] = useState('');

  const [editPromotionDetails, setEditPromotionDetails] = useState('');
  const [editPromotionDetailsError, setEditPromotionDetailsError] = useState(false);
  const [editPromotionDetailsHelperText, setEditPromotionDetailsHelperText] = useState('');

  const [editPromotionDiscount, setEditPromotionDiscount] = useState('');
  const [editPromotionDiscountError, setEditPromotionDiscountError] = useState(false);
  const [editPromotionDiscountHelperText, setEditPromotionDiscountHelperText] = useState('');

  const [editPromotionCategory, setEditPromotionCategory] = useState([]);
  const [editPromotionCategoryError, setEditPromotionCategoryError] = useState(false);
  const [editPromotionCategoryHelperText, setEditPromotionCategoryHelperText] = useState('');

//   const [editPromotionLocations, setEditPromotionLocations] = useState([]);
//   const [editPromotionLocationsError, setEditPromotionLocationsError] = useState(false);
//   const [editPromotionLocationsHelperText, setEditPromotionLocationsHelperText] = useState('');

  const [editPromotionKeyAudience, setEditPromotionKeyAudience] = useState([]);
  const [editPromotionKeyAudienceError, setEditPromotionKeyAudienceError] = useState(false);
  const [editPromotionKeyAudienceHelperText, setEditPromotionKeyAudienceHelperText] = useState('');

  const [editPromotionKeyTarget, setEditPromotionKeyTarget] = useState([]);
  const [editPromotionKeyTargetError, setEditPromotionKeyTargetError] = useState(false);
  const [editPromotionKeyTargetHelperText, setEditPromotionKeyTargetHelperText] = useState('');

  const [editPromotionOverallContentTone, setEditPromotionOverallContentTone] = useState([]);
  const [editPromotionOverallContentToneError, setEditPromotionOverallContentToneError] = useState(false);
  const [editPromotionOverallContentToneHelperText, setEditPromotionOverallContentToneHelperText] = useState('');

  const [editPromotionStartDate, setEditPromotionStartDate] = useState(null);
  const [editPromotionStartDateError, setEditPromotionStartDateError] = useState(false);
  const [editPromotionStartDateHelperText, setEditPromotionStartDateHelperText] = useState('');

  const [editPromotionEndDate, setEditPromotionEndDate] = useState(null);
  const [editPromotionEndDateError, setEditPromotionEndDateError] = useState(false);
  const [editPromotionEndDateHelperText, setEditPromotionEndDateHelperText] = useState('');

  const [editDeliveryPlatformCheckedValues, setEditDeliveryPlatformCheckedValues] = useState([]);
  const [editPromotionDeliveryPlatformError, setEditPromotionDeliveryPlatformError] = useState(false);
  const [editPromotionDeliveryPlatformHelperText, setEditPromotionDeliveryPlatformHelperText] = useState('');

  const [editPromotionLanguageValue, setEditPromotionLanguageValue] = useState('');
  const [editPromotionLanguageValueError, setEditPromotionLanguageValueError] = useState(false);
  const [editPromotionLanguageValueHelperText, setEditPromotionLanguageValueHelperText] = useState('');

  const [editPromotionTypeValue, setEditPromotionTypeValue] = useState('');
  const [editPromotionTypeValueError, setEditPromotionTypeValueError] = useState(false);
  const [editPromotionTypeValueHelperText, setEditPromotionTypeValueHelperText] = useState('');


    const [editPromotionContentToneList, setEditPromotionContentToneList] = useState([]);
    const [editPromotionKeyAudienceList, setEditPromotionKeyAudienceList] = useState([]);
    const [editPromotionKeyTargetsList, setEditPromotionKeyTargetsList] = useState([]);
    const [editPromotionCategoryList, setEditPromotionCategoryList] = useState([]);
    const { setLoading, showToast } = useGlobalContext();
    const editPromotionCategories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];
    // const [formData, setFormData] = useState({
    //     promotionId: "",
    //     userId: "",
    //     promotionName: '',
    //     promotionDetail: '',
    //     promotionDiscount: '',
    //     promotionCategory: '',
    //     promotionType: '',
    //     location: '',
    //     keyAudience: '',
    //     keyTarget: '',
    //     overallContentTone: '',
    //     promotionStartDate: "",
    //     promotionEndDate: "",
    //     deliveryPlatform: [],
    //     promotionLanguage: '',
    // });

    const navigate = useNavigate();

    const getDetailsofEditPromotionApi = async (evenId) => {
        setLoading(true);
        let response = await getPromotionDetailsApi(evenId);
        const { promotion, success } = response.data;
        if (response.status === 200 && success === true) {
            const promotionData = promotion[0];
            setEditPromotionName(promotionData.promotionName)
            setEditPromotionDetails(promotionData.promotionDetail);
            setEditPromotionDiscount(promotionData.promotionDiscount);
            setEditPromotionCategory(promotionData.promotionCategory);
            setEditPromotionTypeValue(promotionData.promotionType);
            //setEditPromotionLocations(promotionData.location);
            setEditPromotionKeyAudience(promotionData.keyAudience);
            setEditPromotionKeyTarget(promotionData.keyTarget);
            setEditPromotionOverallContentTone(promotionData.overallContentTone);
            setEditPromotionStartDate(new Date(promotionData.startDate));       
            setEditPromotionEndDate(new Date(promotionData.endDate));
            setEditDeliveryPlatformCheckedValues(promotionData.deliveryPlatform);
            setEditPromotionLanguageValue(promotionData.promotionLanguage);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (evenId) {
            getDetailsofEditPromotionApi(evenId);
        }
    }, [evenId]);


    useEffect(() => {
        getPromotionFieldsListData()
        //getPromotionCategoryFieldsListData()
    }, [])

    const getPromotionFieldsListData = async () => {
        let response = await getListSelectOptionsApi()
        const { contentTone, keyAudience, keyTargets } = response.data;
        if (response.status === 200 && contentTone.length>0 && keyAudience.length>0 && keyTargets.length>0) {
          setEditPromotionKeyAudienceList(keyAudience);
          setEditPromotionContentToneList(contentTone);
          setEditPromotionKeyTargetsList(keyTargets);
        }
    }

    // const getPromotionCategoryFieldsListData = async () => {
    //     let response = await getPromotionCategoryListApi();
    //     const { category } = response.data;
    //     if (response.status === 200 && category.length>0) {
    //       setEditPromotionCategoryList(category);
    //     }
    // }

    const convertDate = (dateString) => {
        const date = new Date(dateString);
        
        // Get the year, month, and day according to the local timezone
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        
        // Format the date as YYYY-MM-DD
        const formattedDate = `${year}-${month}-${day}`;
        
        // Get the timezone offset in hours and minutes
        const timezoneOffset = -date.getTimezoneOffset(); // getTimezoneOffset() returns the offset in minutes, with the sign reversed
        
        const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
        const timezoneString = `${timezoneOffset >= 0 ? '+' : '-'}${offsetHours}:${offsetMinutes}`;
        
        return `${formattedDate}`;
    };


    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        let valid = true;
    
        if (!editPromotionName) {
          setEditPromotionNameError(true);
          setEditPromotionNameHelperText('Please enter promotion name');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionNameError(false);
          setEditPromotionNameHelperText('');
        }
    
        if (!editPromotionDetails) {
          setEditPromotionDetailsError(true);
          setEditPromotionDetailsHelperText('Please enter promotion details');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionDetailsError(false);
          setEditPromotionDetailsHelperText('');
        }
    
        if (!editPromotionDiscount) {
          setEditPromotionDiscountError(true);
          setEditPromotionDiscountHelperText('Please enter promotion discount');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionDiscountError(false);
          setEditPromotionDiscountHelperText('');
        }
    
        if (editPromotionCategory.length < 1) {
          setEditPromotionCategoryError(true);
          setEditPromotionCategoryHelperText('Please enter promotion category');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionCategoryError(false);
          setEditPromotionCategoryHelperText('');
        }
    
        // if (editPromotionLocations.length < 1) {
        //   setEditPromotionLocationsError(true);
        //   setEditPromotionLocationsHelperText('Please select promotion locations');
        //   valid = false;
        //   setLoading(false);
        // } else {
        //   setEditPromotionLocationsError(false);
        //   setEditPromotionLocationsHelperText('');
        // }
    
        if (editPromotionKeyAudience.length < 1) {
          setEditPromotionKeyAudienceError(true);
          setEditPromotionKeyAudienceHelperText('Please select key audience');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionKeyAudienceError(false);
          setEditPromotionKeyAudienceHelperText('');
        }
    
        if (editPromotionKeyTarget.length < 1) {
          setEditPromotionKeyTargetError(true);
          setEditPromotionKeyTargetHelperText('Please select key target');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionKeyTargetError(false);
          setEditPromotionKeyTargetHelperText('');
        }
    
        if (editPromotionOverallContentTone.length < 1) {
          setEditPromotionOverallContentToneError(true);
          setEditPromotionOverallContentToneHelperText('Please select overall content tone');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionOverallContentToneError(false);
          setEditPromotionOverallContentToneHelperText('');
        }
    
        if (editPromotionStartDate == null) {
          setEditPromotionStartDateError(true);
          setEditPromotionStartDateHelperText('Please select promotion start date');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionStartDateError(false);
          setEditPromotionStartDateHelperText('');
        }
    
        if (!editPromotionEndDate) {
          setEditPromotionEndDateError(true);
          setEditPromotionEndDateHelperText('Please select promotion end date');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionEndDateError(false);
          setEditPromotionEndDateHelperText('');
        }
    
        if (editDeliveryPlatformCheckedValues.length > 0) {
          setEditPromotionDeliveryPlatformError(false);
          setEditPromotionDeliveryPlatformHelperText('');
        } else {
          valid = false;
          setLoading(false);
          setEditPromotionDeliveryPlatformError(true);
          setEditPromotionDeliveryPlatformHelperText('Please select atleast one delivery platform')
          
        }
    
        if (!editPromotionLanguageValue) {
          setEditPromotionLanguageValueError(true);
          setEditPromotionLanguageValueHelperText('Please choose promotion language');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionLanguageValueError(false);
          setEditPromotionLanguageValueHelperText('');
        }
    
        if (!editPromotionTypeValue) {
          setEditPromotionTypeValueError(true);
          setEditPromotionTypeValueHelperText('Please choose promotion type');
          valid = false;
          setLoading(false);
        } else {
          setEditPromotionTypeValueError(false);
          setEditPromotionTypeValueHelperText('');
        }
    
        if (valid === true) {
          try {
            let editPromotionData = {
              promotionId: evenId,
              promotion_name: editPromotionName,
              promotion_detail: editPromotionDetails,
              promotion_discount: editPromotionDiscount,
              promotion_category: editPromotionCategory,
              promotion_type: editPromotionTypeValue,
              //location: editPromotionLocations,
              key_audience: editPromotionKeyAudience,
              key_target: editPromotionKeyTarget,
              overall_content_tone: editPromotionOverallContentTone,
              start_date: convertDate(editPromotionStartDate),
              end_date: convertDate(editPromotionEndDate),
              delivery_platform: editDeliveryPlatformCheckedValues,
              promotion_language: editPromotionLanguageValue,
              userId: localStorage.getItem('userId')
            }
            
            const response = await editPromotionApi(editPromotionData);
            if (response.status === 200 && response.data.success === true) {
              navigate('/app/promotion-list');
              getPromotionLists();
              setLoading(false);
              onClose();
              showToast("Promotion has been edited successfully",'success');
            }
          }catch (error) {
            showToast(error?.response?.data?.error || "Error! Please try again", 'error');
            console.error("Error fetching previous plan:", error);
          } finally {
            setLoading(false);
          }
        }
    };

    const handleEditDeliveryPlatform = (event) => {
        const { name, checked } = event.target;
        setEditDeliveryPlatformCheckedValues((prev) =>
          checked ? [...prev, name] : prev.filter((value) => value !== name)
        );
    };
    
    return (
        <Modal
            open={isVisible}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="promotionEditModalContainer"
        >
            <Box className="promotionEditModalContent">
                <div className='flex items-center justify-end cursor-pointer'>
                <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross-icon' onClick={onClose} />
                </div>
                <Box className="promotionEditModalHeader">
                    <p variant="h6" className="promotionEditModalTitle">Edit Promotion</p>
                    <Box className="promotionEditModalHeaderActions">
                        <Button
                        variant="primary"
                        onClick={handleEditSubmit}
                        >
                        Save Now
                        </Button>
                    </Box>
                </Box>
                <form noValidate autoComplete="off">
                    <div className="promotionEditModalSection">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            placeholder="Please enter promotion name"
                            id="editPromotionName"
                            label="Promotion Name"
                            name="editPromotionName"
                            autoComplete="editPromotionName"
                            autoFocus
                            value={editPromotionName}
                            onChange={(e) => setEditPromotionName(e.target.value)}
                            error={editPromotionNameError}
                            helperText={editPromotionNameHelperText}
                        />
                    </div>
                    <div className="promotionEditModalSection">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            placeholder="Please enter promotion description"
                            id="editPromotionDetails"
                            label="Description"
                            name="editPromotionDetails"
                            value={editPromotionDetails}
                            onChange={(e) => setEditPromotionDetails(e.target.value)}
                            multiline
                            rows={3}
                            error={editPromotionDetailsError}
                            helperText={editPromotionDetailsHelperText}
                        />
                    </div>
                    <div className="promotionEditModalSection">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            placeholder="Please enter promotion discount"
                            id="editPromotionDiscount"
                            label="Discount"
                            name="editPromotionDiscount"
                            value={editPromotionDiscount}
                            onChange={(e) => setEditPromotionDiscount(e.target.value)}
                            error={editPromotionDiscountError}
                            helperText={editPromotionDiscountHelperText}
                        />
                    </div>
                    <div className="promotionEditModalSection">
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel id="promotion-category-label"> Promotion Category*</InputLabel>
                            <Select
                                labelId="promotion-category-label"
                                id="editPromotionCategory"
                                label="Promotion Category"
                                name="editPromotionCategory"
                                value={editPromotionCategory}
                                onChange={(e) => setEditPromotionCategory(e.target.value)}
                                error={editPromotionCategoryError}
                                multiple
                            >
                            {/* {editPromotionCategoryList.map((promotionCatego, index) => 
                                <MenuItem value={promotionCatego.categoryId}>{promotionCatego.categoryName}</MenuItem>
                            )} */}
                            {editPromotionCategories.map((promotionCatego, index) => 
                                <MenuItem key={index} value={promotionCatego}>{promotionCatego}</MenuItem>
                            )}
                            </Select>
                            <FormHelperText>{editPromotionCategoryHelperText}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="promotionEditModalSection">
                        <div className='ml-3'>
                            <FormControl component="fieldset" fullWidth>
                                <RadioGroup
                                aria-label="promotion-type"
                                name="promotionType"
                                row
                                className='gap-[7px]'
                                >
                                <FormControlLabel
                                    value="online"
                                    control={
                                    <Radio 
                                    checked={editPromotionTypeValue === 'online'}  
                                    onChange={(e) => setEditPromotionTypeValue(e.target.value)} 
                                    sx={{marginTop:'12px'}} 
                                    className='editPromotionCustomRadio'
                                    />}
                                    className="border-2	border-[#CCC] rounded-md w-[31%]"
                                    label={
                                    <div>
                                        <div>Online</div>
                                    </div>
                                    }
                                />
                                <FormControlLabel
                                    value="in-store"
                                    control={
                                    <Radio 
                                    checked={editPromotionTypeValue === 'in-store'}  
                                    onChange={(e) => setEditPromotionTypeValue(e.target.value)} 
                                    sx={{marginTop:'12px'}} className='editPromotionCustomRadio'
                                    />}
                                    className="border-2	border-[#CCC] rounded-md w-[31%]"
                                    label={
                                    <div>
                                        <div>In-Store</div>
                                    </div>
                                    }
                                />
                                <FormControlLabel
                                    value="both"
                                    control={
                                    <Radio checked={editPromotionTypeValue === 'both'}  
                                    onChange={(e) => setEditPromotionTypeValue(e.target.value)} 
                                    sx={{marginTop:'12px'}} 
                                    className='editPromotionCustomRadio'
                                    />}
                                    className="border-2	border-[#CCC] rounded-md w-[31%]"
                                    label={
                                    <div>
                                        <div>Both</div>
                                    </div>
                                    }
                                />
                                    <FormHelperText>{editPromotionTypeValueError==true?editPromotionTypeValueHelperText:''}</FormHelperText>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    {/* <div className="promotionEditModalSection">
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>Location</InputLabel>
                            <Select
                                labelId="promotion-locations-label"
                                multiple
                                id="editPromotionLocations"
                                label="editPromotionLocations"
                                name="editPromotionLocations"
                                value={editPromotionLocations}
                                onChange={(e) => setEditPromotionLocations(e.target.value)}
                                error={editPromotionLocationsError}
                            >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="Location1">Location 1</MenuItem>
                                <MenuItem value="Location2">Location 2</MenuItem>
                                <MenuItem value="Location3">Location 3</MenuItem>
                            </Select>
                            <FormHelperText>{editPromotionLocationsHelperText}</FormHelperText>
                        </FormControl>
                    </div> */}
                    <div className="promotionEditModalSection">
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>Key Audience</InputLabel>
                            <Select
                                labelId="promotion-key-audience-label"
                                multiple
                                id="editPromotionKeyAudience"
                                label="Key Audience"
                                name="editPromotionKeyAudience"
                                value={editPromotionKeyAudience}
                                onChange={(e) => setEditPromotionKeyAudience(e.target.value)}
                                error={editPromotionKeyAudienceError}
                            >
                                {editPromotionKeyAudienceList.map((promotionKeyAud, index) => 
                                <MenuItem key={promotionKeyAud.keyAudience} value={promotionKeyAud.keyAudience}>
                                    <ListItemText 
                                    primary={promotionKeyAud.keyAudience}  
                                    secondary={<Typography variant="body2" sx={{fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43'}}>Years born : {promotionKeyAud.bornYear}</Typography>}
                                    />
                                </MenuItem>
                                )}
                            </Select>
                            <FormHelperText>{editPromotionKeyAudienceHelperText}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="promotionEditModalSection">
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>Key Target</InputLabel>
                            <Select
                                labelId="promotion-key-target-label"
                                multiple
                                id="editPromotionKeyTarget"
                                label="Key Target"
                                name="editPromotionKeyTarget"
                                value={editPromotionKeyTarget}
                                onChange={(e) => setEditPromotionKeyTarget(e.target.value)}
                                error={editPromotionKeyTargetError}
                            >
                                {editPromotionKeyTargetsList.map((promotionContentTone, index) =>  
                                    <MenuItem key={index} value={promotionContentTone}>{promotionContentTone}</MenuItem>
                                )} 
                            </Select>
                            <FormHelperText>{editPromotionKeyTargetHelperText}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="promotionEditModalSection">
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>OverallContentTone</InputLabel>
                            <Select
                                labelId="promotion-over-content-tone-label"
                                multiple
                                id="editPromotionOverallContentTone"
                                label="Overall Content Tone"
                                name="editPromotionOverallContentTone"
                                value={editPromotionOverallContentTone}
                                onChange={(e) => setEditPromotionOverallContentTone(e.target.value)}
                                error={editPromotionOverallContentToneError}
                            >
                                {editPromotionContentToneList.map((promotionContentTone, index) =>  
                                    <MenuItem key={index} value={promotionContentTone}>{promotionContentTone}</MenuItem>
                                )} 
                            </Select>
                            <FormHelperText>{editPromotionOverallContentToneHelperText}</FormHelperText>
                        </FormControl>
                    </div>
                    <div className="promotionEditModalSection">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {/* Start Date */}
                            <DatePicker
                            label="Start Date"
                            className='w-[100%]'
                            value={editPromotionStartDate}
                            onChange={(newValue) => setEditPromotionStartDate(newValue)}
                            renderInput={(params) => 
                            <TextField 
                            {...params} 
                            error={editPromotionStartDateError}
                            helperText={editPromotionStartDateHelperText}
                            />}
                            />
                            <FormHelperText>{editPromotionStartDateHelperText}</FormHelperText>
                        </LocalizationProvider>
                    </div>
                    <div className="promotionEditModalSection">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {/* End Date */}
                            <DatePicker
                            label="End Date"
                            className='w-[100%]'
                            value={editPromotionEndDate}
                            onChange={(newValue) => setEditPromotionEndDate(newValue)}
                            minDate={editPromotionStartDate} // Ensures end date is after start date
                            renderInput={(params) => 
                            <TextField 
                                {...params} 
                                error={editPromotionEndDateError}
                                helperText={editPromotionEndDateHelperText}
                            />}
                            />
                            <FormHelperText>{editPromotionEndDateHelperText}</FormHelperText>
                        </LocalizationProvider>
                    </div>
                    <div className="promotionEditModalSection">
                        <p className='promotionEditPlatformTitle' component="legend">Delivery Platforms</p>
                            <div className="flex items-center">
                                <div className="mt-1">
                                    <Checkbox
                                        name="email"
                                        checked={editDeliveryPlatformCheckedValues.includes("email")}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={handleEditDeliveryPlatform}
                                        label="Email"
                                        className='editPromotionCustomCheckbox'
                                    />
                                </div>
                                <div className="flex items-center">
                                    <p className='text-[14px] text[#646464] text[DM Sans]'>Email</p>
                                </div>

                                <div className="mt-1">
                                    <Checkbox
                                        name="sms"
                                        checked={editDeliveryPlatformCheckedValues.includes("sms")}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={handleEditDeliveryPlatform}
                                        className='editPromotionCustomCheckbox'
                                    />
                                </div>
                                <div className="flex items-center">
                                    <p className='text-[14px] text[#646464] text[DM Sans]'>SMS</p>
                                </div>

                                <div className="mt-1">
                                    <Checkbox
                                        name="whatsapp"
                                        checked={editDeliveryPlatformCheckedValues.includes("whatsapp")}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        onChange={handleEditDeliveryPlatform}
                                        className='editPromotionCustomCheckbox'
                                    />
                                </div>
                                <div className="flex items-center">
                                    <p className='text-[14px] text[#646464] text[DM Sans]'>Whatsapp</p>
                                </div>
                                
                            </div>
                            <FormHelperText>{editPromotionDeliveryPlatformError==true?editPromotionDeliveryPlatformHelperText:''}</FormHelperText>    
                    </div>
                    <div className="promotionEditModalSection">
                        <p>Promotion Language</p>
                        <div className="flex items-center">
                            <div className='mt-4'>
                                <Radio 
                                name='promotionLanguage'
                                value='English'
                                checked={editPromotionLanguageValue === 'English'}  
                                onChange={(e) => setEditPromotionLanguageValue(e.target.value)}
                                className='editPromotionCustomRadio'
                                />
                            </div>
                            <div className="flex items-center">
                                <p className='text-[14px] text[#646464] text[DM Sans]'>English</p>
                            </div>
                            <div className='mt-4'>
                                <Radio 
                                name='promotionLanguage'
                                value='Spanish'
                                checked={editPromotionLanguageValue === 'Spanish'}  
                                onChange={(e) => setEditPromotionLanguageValue(e.target.value)}
                                className='editPromotionCustomRadio'
                                />
                            </div>
                            <div className="flex items-center">
                                <p className='text-[14px] text[#646464] text[DM Sans]'>Spanish</p>
                            </div>
                            <div className='mt-4'>
                                <Radio 
                                name='promotionLanguage'
                                value='French'
                                checked={editPromotionLanguageValue === 'French'}  
                                onChange={(e) => setEditPromotionLanguageValue(e.target.value)}
                                className='editPromotionCustomRadio'
                                />
                            </div>
                            <div className="flex items-center">
                                <p className='text-[14px] text[#646464] text[DM Sans]'>French</p>
                            </div>
                            <div className='mt-4'>
                                <Radio 
                                name='promotionLanguage'
                                value='German'
                                checked={editPromotionLanguageValue === 'German'}  
                                onChange={(e) => setEditPromotionLanguageValue(e.target.value)}
                                className='editPromotionCustomRadio'
                                />
                            </div>
                            <div className="flex items-center">
                                <p className='text-[14px] text[#646464] text[DM Sans]'>German</p>
                            </div>
                            <div className='mt-4'>
                                <Radio
                                name='promotionLanguage' 
                                value='Chinese'
                                checked={editPromotionLanguageValue === 'Chinese'}  
                                onChange={(e) => setEditPromotionLanguageValue(e.target.value)}
                                className='editPromotionCustomRadio'
                                />
                            </div>
                            <div className="flex items-center">
                                <p className='text-[14px] text[#646464] text[DM Sans]'>Chinese</p>
                            </div>
                        </div>
                            {/* <FormControlLabel
                                value="English"
                                control={<Radio checked={editPromotionLanguageValue === 'English'}  onChange={(e) => setEditPromotionLanguageValue(e.target.value)}/>}
                                label="English"
                                labelPlacement="end"
                            /> */}
                            {/* <FormControlLabel
                                value="Spanish"
                                control={<Radio  checked={editPromotionLanguageValue === 'Spanish'}  onChange={(e) => setEditPromotionLanguageValue(e.target.value)}/>}
                                label="Spanish"
                                labelPlacement="end"
                            /> 
                            <FormControlLabel
                                value="French"
                                control={<Radio  checked={editPromotionLanguageValue === 'French'}  onChange={(e) => setEditPromotionLanguageValue(e.target.value)}/>}
                                label="French"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="German"
                                control={<Radio  checked={editPromotionLanguageValue === 'German'}  onChange={(e) => setEditPromotionLanguageValue(e.target.value)}/>}
                                label="German"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Chinese"
                                control={<Radio  checked={editPromotionLanguageValue === 'Chinese'}  onChange={(e) => setEditPromotionLanguageValue(e.target.value)}/>}
                                label="Chinese"
                                className='FormControlLabelChinese'
                                labelPlacement="end"
                            /> */}
                            <FormHelperText>{editPromotionLanguageValueError==true?editPromotionLanguageValueHelperText:''}</FormHelperText>
                        
                    </div>
                </form>
            </Box>
        </Modal >
    );
};

export default PromotionCalenderEditModal;
