import React, { useState,useEffect } from 'react';
import {
  Button, Modal, TextField, Typography, Container, Box, Input
} from '@mui/material';
import './BusinessModal.css';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Link } from 'react-router-dom';
import { shopPostApi } from '../../API/ShopAndShopBranch/ShopApi';
import { useGlobalContext } from '../../Context/GlobalContext';

const ModalForm = ({ setBusinessModalOpen, businessModalOpen, handleBusinessModalOpen, handleBusinessModalClose }) => {
  const [shopName, setShopName] = useState('');
  const [shopNameError, setShopNameError] = useState(false);
  const [shopNameHelperText, setShopNameHelperText] = useState('');

  const [shopDescription, setShopDescription] = useState('');
  const [shopDescriptionError, setShopDescriptionError] = useState(false);
  const [shopDescriptionHelperText, setshopDescriptionHelperText] = useState('');

  const [shopEmail, setShopEmail] = useState('');
  const [shopEmailError, setShopEmailError] = useState(false);
  const [shopEmailHelperText, setShopEmailHelperText] = useState('');

  const [shopSource, setShopSource] = useState('');
  const [shopSourceError, setshopSourceError] = useState(false);
  const [shopSourceHelperText, setshopSourceHelperText] = useState('');

  const [otherCrmPlatform, setOtherCrmPlatform] = useState('');
  const [otherCrmPlatformError, setOtherCrmPlatformError] = useState(false);
  const [otherCrmPlatformHelperText, setOtherCrmPlatformHelperText] = useState('');

  const [shopWebsite, setShopWebsite] = useState('');
  const [shopWebsiteError, setShopWebsiteError] = useState('');
  const [shopWebsiteHelperText, setShopWebsiteHelperText] = useState('');

  const [shopLogo, setShopLogo] = useState('');
  const [shopLogoError, setShopLogoError] = useState('');
  const [shopLogoHelperText, setShopLogoHelperText] = useState('');


  const { setLoading, showToast } = useGlobalContext();
  const [businessSavedSuccesfulModalOpen, setBusinessSavedSuccesfulModalOpen] = useState(false);


  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setShopLogo(reader.result);
  //       setShopLogoError(false);
  //       setShopLogoHelperText('');
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     const fileType = file.type.split('/')[0];
  //     if (fileType !== 'image') {
  //       setShopLogoError(true);
  //       setShopLogoHelperText('Please upload a valid image');
  //       return;
  //     }

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setShopLogo(reader.result);
  //       setShopLogoError(false);
  //       setShopLogoHelperText('');
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setShopLogoError(true);
  //     setShopLogoHelperText('No file selected.');
  //   }
  // };


  useEffect(() => {
    if (businessModalOpen) {
      // Reset form fields when modal opens
      setShopName('');
      setShopNameError(false);
      setShopNameHelperText('');

      setShopDescription('');
      setShopDescriptionError(false);
      setshopDescriptionHelperText('');

      setShopEmail('');
      setShopEmailError(false);
      setShopEmailHelperText('');

      setShopSource('');
      setshopSourceError(false);
      setshopSourceHelperText('');

      setOtherCrmPlatform('');
      setOtherCrmPlatformError(false);
      setOtherCrmPlatformHelperText('');

      setShopWebsite('');
      setShopWebsiteError(false);
      setShopWebsiteHelperText('');

      setShopLogo('');
      setShopLogoError(false);
      setShopLogoHelperText('');
    }
  }, [businessModalOpen]);


  const handleBusinessSavedSuccesfulModalOpen = () => {
    setBusinessSavedSuccesfulModalOpen(true);
  };

  const handleBusinessSavedSuccesfulModalClose = () => {
    setBusinessSavedSuccesfulModalOpen(false);
  };

  const validate = (name) => {
    const nameRegex = /^[a-zA-Z0-9-' ]+$/;
    ;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateURL = (url) => {
    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let valid = true;

      if (!validate(shopName)) {
        setShopNameError(true);
        setShopNameHelperText('Please enter your store name by alphabet and digits');
        valid = false;
        setLoading(false);
      } else {
        setShopNameError(false);
        setShopNameHelperText('');
      }
      if (!shopDescription) {
        setShopDescriptionError(true);
        setshopDescriptionHelperText('Please enter your store details');
        valid = false;
        setLoading(false);
      } else {
        setShopDescriptionError(false);
        setshopDescriptionHelperText('');
      }
      if (!validateEmail(shopEmail)) {
        setShopEmailError(true);
        setShopEmailHelperText('Please enter a valid store email address');
        valid = false;
        setLoading(false);
      } else {
        setShopEmailError(false);
        setShopEmailHelperText('');
      }
      if (!validateURL(shopWebsite)) {
        setShopWebsiteError(true);
        setShopWebsiteHelperText('Please enter a valid store website');
        valid = false;
        setLoading(false);
      } else {
        setShopWebsiteError(false);
        setShopWebsiteHelperText('');
      }
      if (!shopSource) {
        setshopSourceError(true);
        setshopSourceHelperText('Please choose the which storesource you are using');
        valid = false;
        setLoading(false);
      } else {
        setshopSourceError(false);
        setshopSourceHelperText('');
      }
      if (!validateURL(shopLogo)) {
        setShopLogoError(true);
        setShopLogoHelperText('Please choose store logo url');
        valid = false;
        setLoading(false);
      } else {
        setShopLogoError(false);
        setShopLogoHelperText('');
      }

      if (!validate(otherCrmPlatform) && shopSource === 'Others') {
        setOtherCrmPlatformError(true);
        setOtherCrmPlatformHelperText('Please enter other platform that you are using');
        valid = false;
        setLoading(false);
      } else {
        setOtherCrmPlatformError(false);
        setOtherCrmPlatformHelperText('');
      }
      if (valid) {
        // const formData = new FormData();
        // formData.append('file', shopLogo);
        // console.log(formData);

        let finisFormData = {
          userId: localStorage.getItem("userId"),
          store_name: shopName,
          store_description: shopDescription,
          store_email: shopEmail,
          source: shopSource,
          store_website: shopWebsite,
          store_logo: shopLogo,
          other_platform_name: otherCrmPlatform,
          // isShopify: false,
          // sourceShopId: null,
        }
        const response = await shopPostApi(finisFormData);
        if (response.status === 200) {
          const { storeId } = response.data;
          if (storeId) {
            localStorage.setItem("storeId", storeId);
            localStorage.setItem("storeExists", true);
          }
          showToast('Registration successful!', 'success');
          setLoading(false);
          handleBusinessModalClose()
          handleBusinessSavedSuccesfulModalOpen()
        } else {
          console.error("Data is not sending. Status:", response.status);
          showToast('Registration failed. Please try again.', 'error');
        }
      }
    } catch (error) {
      setLoading(false);
      showToast(error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error", error)
    }
  };

  return (
    <div>
      <Modal open={businessModalOpen} onClose={handleBusinessModalClose} className="modal">
        <div className="paper">
          <div className='headingCrossPosition'>
            <p className='ModalHeading'>
              Tell us about your store
            </p>
            <div className='crossOnModal' onClick={handleBusinessModalClose}>
              <img src={process.env.REACT_APP_URL + 'Cross-1171276068.svg'} alt='cross' />
            </div>
          </div>
          <form noValidate className="form" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder='Please enter your store name'
              id="shopName"
              label="Store Name*"
              name="shopName"
              autoComplete="shopName"
              autoFocus
              value={shopName}
              onChange={(e) => {
                setShopName(e.target.value);
                setShopNameError(false);
                setShopNameHelperText("");
              }}
              error={shopNameError}
              helperText={shopNameHelperText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              placeholder='Please enter your store description'
              id="shopDescription"
              label="Store Description*"
              name="shopDescription"
              autoComplete="shopDescription"
              autoFocus
              value={shopDescription}
              onChange={(e) => {
                setShopDescription(e.target.value);
                setShopDescriptionError(false);
                setshopDescriptionHelperText("");
              }}
              multiline
              rows={3}
              error={shopDescriptionError}
              helperText={shopDescriptionHelperText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder='Please enter your store email'
              id="shopEmail"
              label="Store Email*"
              name="shopEmail"
              autoComplete="shopEmail"
              autoFocus
              value={shopEmail}
              onChange={(e) => {
                setShopEmail(e.target.value);
                setShopEmailError(false);
                setShopEmailHelperText("");
              }}
              error={shopEmailError}
              helperText={shopEmailHelperText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder='Please enter your store website'
              id="shopWebsite"
              label="Store website"
              name="shopWebsite"
              autoComplete="shopWebsite"
              autoFocus
              value={shopWebsite}
              onChange={(e) => {
                setShopWebsite(e.target.value);
                setShopWebsiteError(false);
                setShopWebsiteHelperText("");
              }}
              error={shopWebsiteError}
              helperText={shopWebsiteHelperText}
            />
            <FormControl variant="outlined" margin="normal" style={{ width: '100%' }}>
              <InputLabel id="role-label">Store Source</InputLabel>
              <Select
                labelId="Shop Source"
                id="shopsource"
                label="shopsource"
                fullWidth
                name="shopsource"
                value={shopSource}
                onChange={(e) => {
                  setShopSource(e.target.value);
                  setshopSourceError(false);
                  setshopSourceHelperText("");
                }}
                error={shopSourceError}
              >
                <MenuItem value='Wix'>Wix</MenuItem>
                <MenuItem value='BigCommerce'>BigCommerce</MenuItem>
                <MenuItem value='Squarespace'>Squarespace</MenuItem>
                <MenuItem value='WooCommerce'>WooCommerce</MenuItem>
                <MenuItem value='Others'>Others</MenuItem>
              </Select>
              <FormHelperText>{shopSourceHelperText}</FormHelperText>
            </FormControl>
            {shopSource === 'Others' && (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="otherCrmPlatform"
                label="Other platform name*"
                name="otherCrmPlatform"
                autoComplete="otherCrmPlatform"
                autoFocus
                value={otherCrmPlatform}
                onChange={(e) => setOtherCrmPlatform(e.target.value)}
                error={otherCrmPlatformError}
                helperText={otherCrmPlatformHelperText}
              />
            )}
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder='Please enter your store logo url'
              id="shoplogourl"
              label="Store logo url"
              name="shoplogourl"
              autoComplete="shoplogourl"
              autoFocus
              value={shopLogo}
              onChange={(e) => {
                setShopLogo(e.target.value);
                setShopLogoError(false);
                setShopLogoHelperText("");
              }}
              error={shopLogoError}
              helperText={shopLogoHelperText}
            />

            {/* <div className="imgUpload">
              <input
                accept="image/png, image/jpeg, image/gif, image/svg+xml"
                className="shopLogo"
                id="file-upload"
                type="file"
                onChange={handleImageChange}
              />
              <label className="imgUploadLabel" htmlFor="file-upload">
                <img src={`${process.env.REACT_APP_URL}upload-minimalistic.svg`} alt="upload location" className="mr-1" />
                {"Upload Store Logo"}
              </label>
            </div>
            <div>
              {shopLogo && (
                <div style={{ marginTop: '20px', width: '200px', height: '200px' }}>
                  <img src={shopLogo} alt="Selected" />
                </div>
              )}
              {shopLogoError && (
                <FormHelperText error>{shopLogoHelperText}</FormHelperText>
              )}
            </div> */}
            <div className='submitButtonContainer'>
              <Button
                type="submit"
                variant="primary"
                className="submitButton"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={businessSavedSuccesfulModalOpen} onClose={handleBusinessSavedSuccesfulModalClose} className="modal">
        <div className="paper">
          {/* <div className='crossOnImgModal' onClick={handleBusinessSavedSuccesfulModalClose}>
                <img src={process.env.REACT_APP_URL+'Cross-1171276068.svg'} alt='cross'/>
            </div> */}
          <div className='imgBackGround'>
            <img src={process.env.REACT_APP_URL + "login-banner.gif"}
              alt='signup banner'
            />
            <Link to='/app/dashboard'>
              <Button
                fullWidth
                className='w-130'
                variant="primary"
              >
                Go to dashboard
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalForm;
