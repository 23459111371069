import React from 'react';
import { Grid, List, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import "./StoreSuperProfile.css";

const StoreSuperProfile = () => {
    return (
        <>
            <div className='storeSuperProfileContainer'>
                <div className="storeProfileContentWrapper" >
                    <Box sx={{ p: 3 }} className="storeDetailsContainer">
                        <Stack spacing={0.5}>
                            <div className="storeName">Veronica Beard</div>
                            <Stack
                                direction="row"
                                spacing={0.5}
                            >
                                <img
                                    src={process.env.REACT_APP_URL + 'OnlineandPhysicalRetail.svg'} alt='OnlineAndPhysicalRetaiIcon'
                                />
                                <div className="storeType">Online and Physical Retail</div>
                            </Stack>
                        </Stack>
                        <div className='storeInfoUpdateContainer'>
                            <div className='storeLocationStack'>
                                <img
                                    src={process.env.REACT_APP_URL + 'location.svg'} alt='LocationIcon'
                                />
                                <div className="storeLocation"> NewYork,USA </div>
                            </div>
                            <div>
                                <Link underline="none" className="updateStoreLink">If you want to update your store details, please click here</Link>
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ p: 3 }}>
                        <div className='descriptionTitle'>
                            Description
                        </div>
                        <Typography variant="body2" className='descriptionText' mt={1}>
                            Trendy Threads is a dynamic fashion destination known for curating the latest
                            styles for men, women, and children. With a strong emphasis on sustainable fashion,
                            this store offers a blend of eco-friendly products and cutting-edge designs,
                            making it a favorite among conscious shoppers. Whether online or in-store,
                            Trendy Threads provides a seamless shopping experience, catering to a
                            diverse clientele with a keen eye for quality and affordability.
                        </Typography>
                    </Box>
                    <Divider variant="middle" className='sectionDivider' />
                    <Box sx={{ p: 3 }}>
                        <Grid container className="productsContainer" >
                            <Grid item xs={12} sm={6} mb={1}>
                                <div
                                    className="topSellingProductsSection" >
                                    <img
                                        src={process.env.REACT_APP_URL + 'productSelling.svg'} alt='TopSellingProductsImage'
                                        className='productImage'
                                    />
                                    <div
                                        className="topSellingProductsTitle"
                                    >Top Selling Products</div>
                                    <ul
                                        className='topSellingProductList'
                                    >
                                        <li className="productItem">Men’s Organic Cotton Tees</li>
                                        <li className="productItem">Women’s Sustainable Denim Jeans</li>
                                        <li className="productItem">Children’s Eco-friendly Activewear</li>
                                        <li className="productItem">Unisex Recycled Material Sneakers</li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} mb={1} >
                                <div className="productCategorySection">
                                    <img
                                        src={process.env.REACT_APP_URL + 'productSelling.svg'} alt='ProductCategoryImage'
                                        className='productImage'
                                    />
                                    <div className="productCategoryTitle">Product Category</div>
                                    <ul className='productCategoryList'>
                                        <li className="categoryItem">Men’s Fashion: Casual wear, Formal wear, Accessories</li>
                                        <li className="categoryItem">Women’s Fashion: Dresses, Tops, Bottoms, Accessories</li>
                                        <li className="categoryItem">Children’s Eco-friendly Activewear</li>
                                        <li className="categoryItem">Footwear: Sneakers, Sandals, Boots</li>
                                        <li className="categoryItem">Footwear: Sneakers, Sandals, Boots</li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider variant="middle" className='sectionDivider' />
                    <Box sx={{ p: 3 }} className="averageSellingPriceContainer">
                        <div className="averageSellingPriceTitle">Average Selling Price</div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className="ageGroupSection">
                                <div className="ageGroupSectionTitle">By Age Group</div>
                                <ul className="ageGroupSectionList">
                                    <li className='ageGroupSectionPrice'>18-25 years: $40 (Online) | $45 (In-store)</li>
                                    <li className='ageGroupSectionPrice'>26-35 years: $60 (Online) | $65 (In-store)</li>
                                    <li className='ageGroupSectionPrice'>36-50 years: $75 (Online) | $80 (In-store)</li>
                                    <li className='ageGroupSectionPrice'>50+ years: $50 (Online) | $55 (In-store)</li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} sm={6} className="shoppingPlatformSection">
                                <div className="shoppingPlatformSectionTitle">By Shopping Platform</div>
                                <ul className='platformPriceList'>
                                    <li className="platformSectionPrice">Online: Average $55 per product</li>
                                    <li className="platformSectionPrice">In-store: Average $60 per product</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                </div >
            </div >
        </>
    )
}

export default StoreSuperProfile
