import React, { useState } from 'react';
import { TextField, Button, FormControlLabel, Checkbox} from '@mui/material';
import  './SignupStyles.css';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {postApi}from '../../apiClient';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useGlobalContext } from '../../Context/GlobalContext';


const SignUp = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState('');
    const [emailHelperText, setEmailHelperText] = useState('');
    const [passwordHelperText, setPasswordHelperText] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameHelperText, setCompanyNameHelperText] = useState('');
    const [website, setWebsite] = useState('');
    const [websiteError, setWebsiteError] = useState(false);
    const [websiteHelperText, setWebsiteHelperText] = useState('');
    const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    companyName:'',
    website:'',
    termsAccepted: false,
  });

  const [formErrors, setFormErrors] = useState({
    termsAccepted: false,
  });

  const { setLoading, showToast } = useGlobalContext();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      fullName:fullName,
      email:email,
      password:password,
      companyName:companyName,
      website:website,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z0-9-' ]+$/ ;
    ;
    return nameRegex.test(name);
  };

  const handleSubmit = async (e) => {
    try{
      e.preventDefault();
      setLoading(true);

      let valid = true;

      if (!validateName(fullName)) {
        setNameError(true);
        setNameHelperText('Please enter your full name by alphabet and digits');
        valid = false;
        setLoading(false);
      } else {
        setNameError(false);
        setNameHelperText('');
      }

      if (!validateEmail(email)) {
        setEmailError(true);
        setEmailHelperText('Please enter a valid email address.');
        valid = false;
        setLoading(false);
      } else {
        setEmailError(false);
        setEmailHelperText('');
      }

      if (!validateName(companyName)) {
        setCompanyNameError(true);
        setCompanyNameHelperText('Please enter a company name.');
        valid = false;
        setLoading(false);
      } else {
        setCompanyNameError(false);
        setCompanyNameHelperText('');
      }
      
      if (website === '') {
        setWebsiteError(true);
        setWebsiteHelperText('Please enter a website.');
        valid = false;
        setLoading(false);
      } else {
        setWebsiteError(false);
        setWebsiteHelperText('');
      }

      if (password.length < 6) {
        setPasswordError(true);
        setPasswordHelperText('Password must be at least 6 characters long.');
        valid = false;
        setLoading(false);
      } else {
        setPasswordError(false);
        setPasswordHelperText('');
      }

      if (!formData.termsAccepted) {
        setFormErrors({ termsAccepted: true });
        valid = false;
        setLoading(false);
        return;
      }
      if (valid) {
        // Handle form submission logic here
        let response = await postApi('api/register',formData)
          const { access_token, user } = response.data;
          if (access_token) {
            localStorage.setItem('fsap', user.email);
            showToast('Registration successful!', 'success');
            navigate('/signup/verify-email?id='+user.userId);
            setLoading(false);
          }
      }
    }catch (error) {
      setLoading(false);
      showToast( error?.response?.data?.err?.message ?? "Error! please try again", 'error');
      console.log("error",error)
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="signUpForm">
      <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="fullName"
          label="Full Name"
          name="fullName"
          autoComplete="fullName"
          autoFocus
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          error={nameError}
          helperText={nameHelperText}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={emailError}
        helperText={emailHelperText}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="companyName"
        label="Company Name"
        name="companyName"
        autoComplete="companyName"
        autoFocus
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        error={companyNameError}
        helperText={companyNameHelperText}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="website"
        label="Website"
        name="website"
        autoComplete="website"
        autoFocus
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
        error={websiteError}
        helperText={websiteHelperText}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type={showPassword ? 'text' : 'password'}
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={passwordError}
        helperText={passwordHelperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className='termCondition'>
        <Checkbox checked={formData.termsAccepted} onChange={handleChange} name="termsAccepted"/> 
        By clicking the Sign Up button you agree to PushSend Ai’s <Link to="/">Terms of Use and Privacy Policy</Link>
      </div>
      
      {formErrors.termsAccepted && (
        <p variant="body2" color="error" className='text-[12px] font-dm-sans text-[#d32f2f]'>
          You must agree to the terms of use and privacy policy
        </p>
      )}
      <div className="mt-2">
      <Button
        type="submit"
        variant="primary"
        fullWidth
      >
        Sign Up
      </Button>
      </div>
      <div><p className='alreadyAccountText'>Already have an account?<Link to="/login" className='btnLoginSignupPage'>Login now</Link></p></div>
    </form>
    
  );
};

export default SignUp;
