import React, { useState } from 'react';
import "./CustomerListSidebar.css";

const CustomerListSidebar = ({ onCustomerSelect }) => {
    const [buttonClicked, setButtonClicked] = useState(false);

    const handleClicked = () => {
        const token = true;
        setButtonClicked(true);
        onCustomerSelect(token);
    }
    return (
        <>
            <div className='customerListContainer'>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Tom Johnson.svg'}
                        className={buttonClicked ? 'customerListImage' : ''}
                    />
                    <button
                        onClick={handleClicked}
                        className={buttonClicked ? 'customerNameButtonActive' : 'customerNameButtonInactive'}
                    >
                        Jessica Walter
                    </button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Tom Johnson.svg'} />
                    <button className='customerNameButtonInactive'>Tom Johnson</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Bruce Miller.svg'} />
                    <button className='customerNameButtonInactive'>Bruce Miller</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Timothy walker.svg'} />
                    <button className='customerNameButtonInactive'>Timothy Walker</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Janney Smuck.svg'} />
                    <button className='customerNameButtonInactive'>Janney Smuck</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Pam Walter.svg'} />
                    <button className='customerNameButtonInactive'>Pam Walter</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Jannet Wong.svg'} />
                    <button className='customerNameButtonInactive'>Jannet Wong</button>
                </div>
                <div className='customerListItem'>
                    <img src={process.env.REACT_APP_URL + 'Logan Paul.svg'} />
                    <button className='customerNameButtonInactive'>Logan Paul</button>
                </div>
            </div>

        </>
    )
}

export default CustomerListSidebar;
