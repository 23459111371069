import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import SignupPage from "../Pages/SignupPage/SignupPage";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage/ForgotPasswordPage";
import VerifyEmailPage from "../Pages/VerifyEmailPage/VerifyEmailPage";
import ActiveEmailPage from "../Pages/ActiveEmailPage/ActiveEmailPage";
import ResetPasswordPage from "../Pages/ResetPasswordPage/ResetPasswordPage";
import SubscriptionPlanPage from "../Pages/SubscriptionPlanPage/SubscriptionPlanPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import OnboardingPage from "../Pages/OnboardingPage/OnboardingPage";
import UpgradeSubscription from "../Pages/UpgradeSubscriptionPage/UpgradeSubscriptionPage";
import PromotionList from "../Pages/PromotionListPage/PromotionListPage";
import StoreSetting from "../Pages/StoreSettingPage/StoreSettingPage";
import InboxPage from "../Pages/InboxPage/InboxPage";
import AnalyticsPage from "../Pages/AnalyticsPage/AnalyticsPage";
import SuggestedPromotionComponent from "../Components/SuggestedPromotionComponent/SuggestedPromotionComponent";
import InboxMessageContent from "../Components/InBoxMessageListComponent/InboxMessageContent";
import StoreSuperProfile from "../Components/StoreSuperProfileComponent/StoreSuperProfile";
import CustomerSuperProfile from "../Components/CustomarSuperProfileComponent/CustomerProfile";



import AppLayout from "./AppLayout/AppLayout";
import { isAuthenticated } from './auth';
import CreatePromotion from "../Pages/CreatePromotion/CreatePromotion";
import ShopBranchList from "../Components/ShopBranchListComponent/ShopBranchList";
import ShopBranchComponent from "../Components/ShopBranchComponent/ShopBranchComponent";
const Layout = () => {
  return (
    <Routes>
      {/* <Route exact path="/" element={<HomePage />}></Route> */}
      <Route exact path="/" element={<LoginPage />}></Route>
      <Route exact path="/signup" element={<SignupPage />}></Route>
      <Route exact path="/signup/verify-email" element={<VerifyEmailPage />}></Route>
      <Route exact path="/on-boarding" element={<ActiveEmailPage />}></Route>
      <Route exact path="/login" element={<LoginPage />}></Route>
      <Route exact path="/forgot-password" element={<ForgotPasswordPage />}></Route>
      <Route exact path="/reset-password" element={<ResetPasswordPage />}></Route>
      <Route exact path="/subscription-plan" element={<SubscriptionPlanPage />}></Route>
      <Route path="/app" element={isAuthenticated() ? <AppLayout /> : <Navigate to="/login" />}>
        <Route path="dashboard" element={isAuthenticated() ? <DashboardPage /> : <Navigate to="/login" />} />
        <Route path="onboarding" element={isAuthenticated() ? <OnboardingPage /> : <Navigate to="/login" />} />
        <Route path="shop-branch" element={isAuthenticated() ? <ShopBranchComponent /> : <Navigate to="/login" />} />
        <Route path="shop-branch-list" element={isAuthenticated() ? <ShopBranchList /> : <Navigate to="/login" />} />
        <Route path="subscription" element={isAuthenticated() ? <UpgradeSubscription /> : <Navigate to="/login" />} />
        <Route path="create-promotion" element={isAuthenticated() ? <CreatePromotion /> : <Navigate to="/login" />} />
        <Route path="suggested-promotion" element={isAuthenticated() ? <SuggestedPromotionComponent /> : <Navigate to="/login" />} />
        <Route path="promotion-list" element={isAuthenticated() ? <PromotionList /> : <Navigate to="/login" />} />
        <Route path="store-settings"  element={isAuthenticated() ? <StoreSetting/> : <Navigate to="/login" />} />
        <Route path="inbox" element={isAuthenticated() ? <InboxPage /> : <Navigate to="/login" />} />
        <Route path="analytics" element={isAuthenticated() ? <AnalyticsPage /> : <Navigate to="/login" />} />
        <Route path="inboxContent" element={isAuthenticated() ? <InboxMessageContent /> : <Navigate to="/login" />} />
        <Route path="store-super-profile" element={isAuthenticated() ? <StoreSuperProfile /> : <Navigate to="/login" />} />
        {/* <Route path="store-super-profile" element={isAuthenticated() ? <StoreSuperProfile /> : <Navigate to="/login" />} />
        <Route path="store-super-profile" element={isAuthenticated() ? <StoreSuperProfile /> : <Navigate to="/login" />} /> */}
        <Route path="customer-super-profile" element={isAuthenticated() ? <CustomerSuperProfile /> : <Navigate to="/login" />} />

      </Route>
    </Routes>
  );
};

export default Layout;
