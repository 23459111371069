import React, { useEffect, useState } from 'react';
import { BarChart, YAxis } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const PriceSensitivityBarChart = () => {

    const [barchartWidth, setBarChartWidth] = useState(window.innerWidth < 600 ? "400" : 500);
    const [barchartHeight, setBarChartHeight] = useState(window.innerWidth < 600 ? "300" : 320);

    const data = [
        { name: '10%', pv: 1800, uv: 1600 },
        { name: '20%', pv: 1800, uv: 1400 },
        { name: '30%', pv: 1600, uv: 1900 },
        { name: '40%', pv: 1980, uv: 2020 },
        { name: '50%', pv: 1720, uv: 2680 },
        { name: '60%', pv: 1800, uv: 3800 },
    ];

    const pData = data.map(item => item.pv);
    const uData = data.map(item => item.uv);
    const xLabels = data.map(item => item.name);

    const handleResize = () => {
        setBarChartWidth(window.innerWidth < 600 ? "400" : 500);
        setBarChartHeight(window.innerWidth < 600 ? "300" : 320);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <BarChart
                sx={() => ({
                    [`.${axisClasses.root}`]: {
                        [`.${axisClasses.tickLabel}`]: {
                            fill: '#5F73AE',
                        },
                    },
                })}
                width={barchartWidth}
                height={barchartHeight}
                slotProps={{
                    legend: {
                        direction: 'column',
                        position: { vertical: 'top', horizontal: 'right' },
                        padding: 10,
                    },
                }}
                grid={{ horizontal: true }}
                series={[
                    {
                        data: pData,
                        label: 'Actual Order',
                        id: 'pvId',
                        stack: 'total',
                        color: "#E3DAFF",
                        barSize: 10,
                    },
                    {
                        data: uData,
                        label: 'Likely to Buy',
                        id: 'uvId',
                        stack: 'total',
                        color: "#711FFF",
                        barSize: 20,
                    },
                ]}
                xAxis={[{
                    data: xLabels,
                    scaleType: 'band',
                    disableLine: true,
                    disableTicks: true,
                    tickFontSize: "12",
                    tickLabelPlacement: "middle",
                    tickLabelStyle: {
                        colors: "#5F73AE",
                        fontWeight: "bold",
                    }
                }]}
                yAxis={[{
                    min: 1000,
                    max: 6000,
                    valueFormatter: (value) => {
                        const formattedValue = (value / 1000).toFixed(0);
                        return `${formattedValue}K`;
                    },
                    disableLine: true,
                    disableTicks: true,
                    tickFontSize: "12",
                    tickLabelPlacement: "middle",
                    tickLabelStyle: {
                        colors: "#5F73AE",
                        fontWeight: "bold",
                    },
                }]}
            />
        </div>
    );
}

export default PriceSensitivityBarChart;
