import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import "./SuggestedPromotionComponent.css";

const SuggestedPromotionComponent = () => {
    return (
        <>
            <div className='suggestedPromotionContainer'>
                <div className='suggestedPromotionWrapper'>
                    <div>
                        <TextField
                            type="search"
                            id="outlined-search"
                            variant="outlined"
                            placeholder="Search"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={process.env.REACT_APP_URL + 'search.svg'} />
                                    </InputAdornment>
                                ),
                            }}
                            className='searchField'
                        />
                    </div>
                    <Grid container spacing={2} className='promotionGridContainer' >
                        <Grid item xs={12} sm={4}>
                            <div className='suggestedPromotionCard'>
                                <div className='suggestedPromotionCardTitle'>Mother's Day Celebration</div>
                                <div className='suggestedPromotionCardSubtitle'>Dapper Moms Collection</div>
                                <p className='suggestedPromotionCardDescription'>
                                    Highlight a special collection of...
                                    sophisticated women's apparel and
                                </p>
                                <div className='suggestedPromotionDateSection'>
                                    <div className='suggestedPromotionStartDate'>
                                        <div className='suggestedPromotionStartDateLabel'>Start Date</div>
                                        <div className='suggestedPromotionStartDateValue'>1st June</div>
                                    </div>
                                    <div className='suggestedPromotionEndDate'>
                                        <div className='suggestedPromotionEndDateLabel'>
                                            End Date
                                        </div>
                                        <div className='suggestedPromotionEndDateValue'>
                                            13th June
                                        </div>
                                    </div>
                                </div>
                                <div className='createPromotionButtonWrapper'>
                                    <img src={process.env.REACT_APP_URL + 'suggestedPromotionIcon.svg'} />
                                    <button className='createPromotionButton'>Create Promotion</button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className='suggestedPromotionCard'>
                                <div className='suggestedPromotionCardTitle'>Summer Solstice Sale</div>
                                <div className='suggestedPromotionCardSubtitle'>Sun-Kissed Styles</div>
                                <p className='suggestedPromotionCardDescription'>
                                    Offer discounts on summer essentials...
                                    like lightweight tops
                                </p>
                                <div className='suggestedPromotionDateSection'>
                                    <div className='suggestedPromotionStartDate'>
                                        <div className='suggestedPromotionStartDateLabel'>Start Date</div>
                                        <div className='suggestedPromotionStartDateValue'>1st June</div>
                                    </div>
                                    <div className='suggestedPromotionEndDate'>
                                        <div className='suggestedPromotionEndDateLabel'>
                                            End Date
                                        </div>
                                        <div className='suggestedPromotionEndDateValue'>
                                            13th June
                                        </div>
                                    </div>
                                </div>
                                <div className='createPromotionButtonWrapper'>
                                    <img src={process.env.REACT_APP_URL + 'suggestedPromotionIcon.svg'} />
                                    <button className='createPromotionButton'>Create Promotion</button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className='suggestedPromotionCard'>
                                <div className='suggestedPromotionCardTitle'>National Sunglasses Day</div>
                                <div className='suggestedPromotionCardSubtitle'>Shades of Cool</div>
                                <p className='suggestedPromotionCardDescription'>
                                    Run a promotion featuring trendy...
                                    sunglasses to celebrate this fun
                                </p>
                                <div className='suggestedPromotionDateSection'>
                                    <div className='suggestedPromotionStartDate'>
                                        <div className='suggestedPromotionStartDateLabel'>Start Date</div>
                                        <div className='suggestedPromotionStartDateValue'>1st June</div>
                                    </div>
                                    <div className='suggestedPromotionEndDate'>
                                        <div className='suggestedPromotionEndDateLabel'>
                                            End Date
                                        </div>
                                        <div className='suggestedPromotionEndDateValue'>
                                            13th June
                                        </div>
                                    </div>
                                </div>
                                <div className='createPromotionButtonWrapper'>
                                    <img src={process.env.REACT_APP_URL + 'suggestedPromotionIcon.svg'} />
                                    <button className='createPromotionButton'>Create Promotion</button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>


        </>
    )
}

export default SuggestedPromotionComponent
