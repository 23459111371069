import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  Link,
  Button,
  FormHelperText,
  ListItemText
} from "@mui/material";
import { Box, Tabs, Tab, styled } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import "./CreatePromotionStyles.css";
import EmailIcon from "@mui/icons-material/Email";
import { useGlobalContext } from '../../Context/GlobalContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
import { createPromotionApi, getListSelectOptionsApi, getPromotionCategoryListApi, getStoreDetailsApi } from '../../API/createPromotion/createPromotion';

// Create styled Tabs and Tab components
const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    backgroundColor: "transparent",
    borderRadius: "0",
    display: "block",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: "auto",
  fontSize: "inherit",
  color: "inherit",
  textTransform: "inherit",
  borderRadius: "10px 10px 0 0",
  color: "#a3aed0",
  background: "#f3f5fc !important",
  marginRight: "12px",
  "&.Mui-selected": {
    borderRadius: "10px 10px 0 0",
    color: "#694fff",
    fontWeight: "500",
    width: "auto",
    background: "#f3f5fc !important",
    borderBottom: "4px solid #694fff",
  },
}));

const CreatePromotion = () => {
  
  const userEmail = localStorage.getItem('ownerEmail');
  const [currentStep, setCurrentStep] = useState(1);
  const [value, setValue] = useState("one");
  const [promotionName, setPromotionName] = useState('');
  const [promotionNameError, setPromotionNameError] = useState(false);
  const [promotionNameHelperText, setPromotionNameHelperText] = useState('');

  const [promotionDetails, setPromotionDetails] = useState('');
  const [promotionDetailsError, setPromotionDetailsError] = useState(false);
  const [promotionDetailsHelperText, setPromotionDetailsHelperText] = useState('');

  const [promotionDiscount, setPromotionDiscount] = useState('');
  const [promotionDiscountError, setPromotionDiscountError] = useState(false);
  const [promotionDiscountHelperText, setPromotionDiscountHelperText] = useState('');

  const [promotionCategory, setPromotionCategory] = useState([]);
  const [promotionCategoryError, setPromotionCategoryError] = useState(false);
  const [promotionCategoryHelperText, setPromotionCategoryHelperText] = useState('');

  // const [promotionLocations, setPromotionLocations] = useState([]);
  // const [promotionLocationsError, setPromotionLocationsError] = useState(false);
  // const [promotionLocationsHelperText, setPromotionLocationsHelperText] = useState('');

  const [promotionKeyAudience, setPromotionKeyAudience] = useState('');
  const [promotionKeyAudienceError, setPromotionKeyAudienceError] = useState(false);
  const [promotionKeyAudienceHelperText, setPromotionKeyAudienceHelperText] = useState('');

  const [promotionKeyTarget, setPromotionKeyTarget] = useState([]);
  const [promotionKeyTargetError, setPromotionKeyTargetError] = useState(false);
  const [promotionKeyTargetHelperText, setPromotionKeyTargetHelperText] = useState('');

  const [promotionOverallContentTone, setPromotionOverallContentTone] = useState([]);
  const [promotionOverallContentToneError, setPromotionOverallContentToneError] = useState(false);
  const [promotionOverallContentToneHelperText, setPromotionOverallContentToneHelperText] = useState('');

  const [promotionStartDate, setPromotionStartDate] = useState(null);
  const [promotionStartDateError, setPromotionStartDateError] = useState(false);
  const [promotionStartDateHelperText, setPromotionStartDateHelperText] = useState('');

  const [promotionEndDate, setPromotionEndDate] = useState(null);
  const [promotionEndDateError, setPromotionEndDateError] = useState(false);
  const [promotionEndDateHelperText, setPromotionEndDateHelperText] = useState('');

  const [deliveryPlatformCheckedValues, setDeliveryPlatformCheckedValues] = useState([]);
  const [promotionDeliveryPlatformError, setPromotionDeliveryPlatformError] = useState(false);
  const [promotionDeliveryPlatformHelperText, setPromotionDeliveryPlatformHelperText] = useState('');

  const [promotionLanguageValue, setPromotionLanguageValue] = useState('');
  const [promotionLanguageValueError, setPromotionLanguageValueError] = useState(false);
  const [promotionLanguageValueHelperText, setPromotionLanguageValueHelperText] = useState('');

  const [promotionTypeValue, setPromotionTypeValue] = useState('');
  const [promotionTypeValueError, setPromotionTypeValueError] = useState(false);
  const [promotionTypeValueHelperText, setPromotionTypeValueHelperText] = useState('');

  const [createPromotionContentToneList, setCreatePromotionContentToneList] = useState([]);
  const [createPromotionKeyAudienceList, setCreatePromotionKeyAudienceList] = useState([]);
  const [createPromotionKeyTargetsList, setCreatePromotionKeyTargetsList] = useState([]);
  const [createPromotionCategoryList, setCreatePromotionCategoryList] = useState([]);
  const [onboardingStoreDetails, setOnboardingStoreDetails] = useState([]);

  const { setLoading, showToast } = useGlobalContext();

  const navigate = useNavigate();

  const promotionCategories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];

  const promotionToday = new Date();

  useEffect(() => {
    //getPromotionCategoryList();
    getSelectOptionsData();
  }, []);

  useEffect(()=>{
    getOnboadingStoreDetailsApi();
  },[]);

  const getOnboadingStoreDetailsApi = async () => {
    setLoading(true);
    let response = await getStoreDetailsApi(userEmail);
    const { stores } = response.data;
    if (response.status === 200 && stores.length>0) {
      setOnboardingStoreDetails(stores[0]);
      //setPromotionCategory(stores[0]?.storeAttribute?.category);
      setPromotionOverallContentTone(stores[0]?.storeAttribute?.contentTone);
      setPromotionKeyAudience(stores[0]?.storeAttribute?.keyAudience);
      setPromotionKeyTarget(stores[0]?.storeAttribute?.keyTargets);
      // let storeLocations = [];
      // stores[0]?.locations?.map((storeLoc)=>{
      //   storeLocations.push(storeLoc.formattedAddress)
      // });
      // setPromotionLocations(storeLocations);
    }
    setLoading(false);
  }

  // const getPromotionCategoryList = async () => {
  //   let response = await getPromotionCategoryListApi();
  //   const { category } = response.data;
  //   if (response.status === 200 && category.length>0) {
  //     setCreatePromotionCategoryList(category)
  //   }
  // }

  const getSelectOptionsData = async () => {
    let response = await getListSelectOptionsApi()
    const { contentTone, keyAudience, keyTargets } = response.data;
    if (response.status === 200 && contentTone.length>0 && keyAudience.length>0 && keyTargets.length>0) {
      setCreatePromotionKeyAudienceList(keyAudience)
      setCreatePromotionContentToneList(contentTone)
      setCreatePromotionKeyTargetsList(keyTargets)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeliveryPlatformChange = (event) => {
    const { name, checked } = event.target;
    //setDeliveryPlatformCheckedValues((prev) => ([ ...prev, name]));
    setDeliveryPlatformCheckedValues((prev) =>
      checked ? [...prev, name] : prev.filter((value) => value !== name)
    );
    setPromotionDeliveryPlatformError(false);
    setPromotionDeliveryPlatformHelperText('');
  };

  const handleCategoryCheckboxChange = (e) => {
    if (e.target.checked) {
      setPromotionCategory(promotionCategories);
    } else {
      setPromotionCategory([]);
    }
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z0-9-' ]+$/ ;
    ;
    return nameRegex.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let valid = true;

    if (!validateName(promotionName)) {
      setPromotionNameError(true);
      setPromotionNameHelperText('Please enter promotion name');
      valid = false;
      setLoading(false);
    } else {
      setPromotionNameError(false);
      setPromotionNameHelperText('');
    }

    if (!promotionDetails) {
      setPromotionDetailsError(true);
      setPromotionDetailsHelperText('Please enter promotion details');
      valid = false;
      setLoading(false);
    } else {
      setPromotionDetailsError(false);
      setPromotionDetailsHelperText('');
    }

    if (!promotionDiscount) {
      setPromotionDiscountError(true);
      setPromotionDiscountHelperText('Please enter promotion discount');
      valid = false;
      setLoading(false);
    } else {
      setPromotionDiscountError(false);
      setPromotionDiscountHelperText('');
    }

    if (promotionCategory.length < 1) {
      setPromotionCategoryError(true);
      setPromotionCategoryHelperText('Please enter promotion category');
      valid = false;
      setLoading(false);
    } else {
      setPromotionCategoryError(false);
      setPromotionCategoryHelperText('');
    }

    // if (promotionLocations.length < 1) {
    //   setPromotionLocationsError(true);
    //   setPromotionLocationsHelperText('Please select promotion locations');
    //   valid = false;
    //   setLoading(false);
    // } else {
    //   setPromotionLocationsError(false);
    //   setPromotionLocationsHelperText('');
    // }

    if (promotionKeyAudience == '') {
      setPromotionKeyAudienceError(true);
      setPromotionKeyAudienceHelperText('Please select key audience');
      valid = false;
      setLoading(false);
    } else {
      setPromotionKeyAudienceError(false);
      setPromotionKeyAudienceHelperText('');
    }

    if (promotionKeyTarget.length < 1) {
      setPromotionKeyTargetError(true);
      setPromotionKeyTargetHelperText('Please select key target');
      valid = false;
      setLoading(false);
    } else {
      setPromotionKeyTargetError(false);
      setPromotionKeyTargetHelperText('');
    }

    if (promotionOverallContentTone.length < 1) {
      setPromotionOverallContentToneError(true);
      setPromotionOverallContentToneHelperText('Please select overall content tone');
      valid = false;
      setLoading(false);
    } else {
      setPromotionOverallContentToneError(false);
      setPromotionOverallContentToneHelperText('');
    }

    if (promotionStartDate == null) {
      setPromotionStartDateError(true);
      setPromotionStartDateHelperText('Please select promotion start date');
      valid = false;
      setLoading(false);
    } else {
      setPromotionStartDateError(false);
      setPromotionStartDateHelperText('');
    }

    if (!promotionEndDate) {
      setPromotionEndDateError(true);
      setPromotionEndDateHelperText('Please select promotion end date');
      valid = false;
      setLoading(false);
    } else {
      setPromotionEndDateError(false);
      setPromotionEndDateHelperText('');
    }

    if (deliveryPlatformCheckedValues.length > 0) {
      setPromotionDeliveryPlatformError(false);
      setPromotionDeliveryPlatformHelperText('');
    } else {
      valid = false;
      setLoading(false);
      setPromotionDeliveryPlatformError(true);
      setPromotionDeliveryPlatformHelperText('Please select atleast one delivery platform')
      
    }

    if (!promotionLanguageValue) {
      setPromotionLanguageValueError(true);
      setPromotionLanguageValueHelperText('Please choose promotion language');
      valid = false;
      setLoading(false);
    } else {
      setPromotionLanguageValueError(false);
      setPromotionLanguageValueHelperText('');
    }

    if (!promotionTypeValue) {
      setPromotionTypeValueError(true);
      setPromotionTypeValueHelperText('Please choose promotion type');
      valid = false;
      setLoading(false);
    } else {
      setPromotionTypeValueError(false);
      setPromotionTypeValueHelperText('');
    }

    const convertDate = (dateString) => {
      const date = new Date(dateString);
      // Get the year, month, and day according to the local timezone
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
      const day = String(date.getDate()).padStart(2, '0');
      // Format the date as YYYY-MM-DD
      const formattedDate = `${year}-${month}-${day}`;
      // Get the timezone offset in hours and minutes
      const timezoneOffset = -date.getTimezoneOffset(); // getTimezoneOffset() returns the offset in minutes, with the sign reversed
      const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
      const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
      const timezoneString = `${timezoneOffset >= 0 ? '+' : '-'}${offsetHours}:${offsetMinutes}`;
      return `${formattedDate}`;
    };

    if (valid === true) {
      try {
        let createPromotionData = {
          promotion_name: promotionName,
          promotion_detail: promotionDetails,
          promotion_discount: promotionDiscount,
          promotion_category: promotionCategory,
          promotion_type: promotionTypeValue,
          //location: promotionLocations,
          key_audience: [promotionKeyAudience],
          key_target: promotionKeyTarget,
          overall_content_tone: promotionOverallContentTone,
          start_date: convertDate(promotionStartDate),
          end_date: convertDate(promotionEndDate),
          delivery_platform: deliveryPlatformCheckedValues,
          promotion_language: promotionLanguageValue,
          userId: localStorage.getItem('userId'),
          storeId: localStorage.getItem('storeId'),
        }
        console.log("createPromotionData",createPromotionData);
        
        const response = await createPromotionApi(createPromotionData);
        if (response.status === 200 && response.data.success === true) {
          navigate('/app/promotion-list');
          setLoading(false);
          showToast("Promotion has been created successfully",'success')
        }
        // setCurrentStep(2);
      }catch (error) {
        showToast(error?.response?.data?.error || "Error! Please try again", 'error');
        console.error("Error fetching previous plan:", error);
      } finally {
        setLoading(false);
      }
      //setCurrentStep(2);
    }
  };

  const handleGenerate = () => {};

  const handleStartDateChange = (newValue) => {
    setPromotionStartDate(newValue);
    setPromotionStartDateError(false);
    setPromotionStartDateHelperText('');
    if (promotionEndDate && newValue > promotionEndDate) {
      setPromotionEndDate(null);
    }
  };

  return (
    <div className="createPromotionContainer">
      {currentStep === 1 && (
        <Grid container direction="column" className="root">
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">Promotion Details</p>
                  <p className="createPromotionDescription">
                    Choose an event, name your promotion, describe it, and pick a
                    product category.<br/>
                    <Link style={{ cursor: "pointer" }}>Learn more</Link>
                  </p>
                </Grid>

                <Grid item xs={12} md={6}>
                  
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      placeholder="Please enter promotion name"
                      id="promotionName"
                      label="Promotion Name"
                      name="promotionName"
                      value={promotionName}
                      onChange={(e) => 
                        {
                          setPromotionName(e.target.value);
                          setPromotionNameError(false);
                          setPromotionNameHelperText('');
                        }
                      }
                      error={promotionNameError}
                      helperText={promotionNameHelperText}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Please enter promotion details"
                      id="promotionDetails"
                      label="Promotion Details"
                      name="promotionDetails"
                      autoComplete="promotionDetails"
                      multiline
                      rows={2}
                      value={promotionDetails}
                      onChange={(e) => 
                        {
                          setPromotionDetails(e.target.value);
                          setPromotionDetailsError(false);
                          setPromotionDetailsHelperText('');
                        }
                      }
                      error={promotionDetailsError}
                      helperText={promotionDetailsHelperText}
                    /> 

                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <TextField
                          type="number"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          placeholder="Please enter promotion discount"
                          id="promotionDiscount"
                          label="Promotion Discount"
                          name="promotionDiscount"
                          autoComplete="off"
                          value={promotionDiscount}
                          onChange={(e) => 
                            {
                              setPromotionDiscount(e.target.value);
                              setPromotionDiscountError(false);
                              setPromotionDiscountHelperText('');
                            }
                          }
                          error={promotionDiscountError}
                          helperText={promotionDiscountHelperText}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            marginTop: "6px",
                            color: "#4318ff",
                            borderColor: "#4318ff",
                            height: "38px",
                          }}
                          onClick={handleGenerate}
                        >
                          Generate
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" margin="normal" className="w-[100%]">
                          <InputLabel id="promotion-category-label"> Promotion Category *</InputLabel>
                          <Select
                            labelId="promotion-category-label"
                            multiple
                            id="promotionCategory"
                            label="Promotion Category"
                            name="promotionCategory"
                            value={promotionCategory}
                            onChange={(e) => 
                              {
                                setPromotionCategory(e.target.value);
                                setPromotionCategoryError(false);
                                setPromotionCategoryHelperText('');
                              }
                            }
                            error={promotionCategoryError}
                          >
                            {/* {createPromotionCategoryList.map((promotionCatego, index) => 
                              <MenuItem value={promotionCatego.categoryId}>{promotionCatego.categoryName}</MenuItem>
                            )} */}
                            {promotionCategories.map((category, index) => 
                              <MenuItem key={index} value={category}>{category}</MenuItem>
                            )}
                          </Select>
                          <FormHelperText>{promotionCategoryHelperText}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} marginTop={"6px"}>
                        <Checkbox  onChange={handleCategoryCheckboxChange} name="selectAll" className="createPromotionCustomCheckbox"/> 
                        Select all categories
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ margin: "30px 0px", color: "#e3e3e3" }} />
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">
                    Promotion Type
                  </p>
                  <p className="createPromotionDescription">
                    Define where you want to sell your products.
                  </p>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                      <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                          aria-label="promotion-type"
                          name="promotionType"
                          row
                        >
                          <FormControlLabel
                            value="online"
                            control={
                            <Radio  
                            checked={promotionTypeValue === 'online'}  
                            onChange={(e) => 
                              {
                                setPromotionTypeValue(e.target.value);
                                setPromotionTypeValueError(false);
                                setPromotionTypeValueHelperText('');
                              }
                            }
                            className="createPromotionCustomRadio"
                            />}
                            className="border-2	border-[#CCC] p-1 rounded-md"
                            label={
                              <div>
                                <div>Online</div>
                                <div>Create promotion</div>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="in-store"
                            control={
                            <Radio 
                            checked={promotionTypeValue === 'in-store'}  
                            onChange={(e) => 
                              {
                                setPromotionTypeValue(e.target.value);
                                setPromotionTypeValueError(false);
                                setPromotionTypeValueHelperText('');
                              }
                            }
                            className="createPromotionCustomRadio"
                            />}
                            className="border-2	border-[#CCC] p-2 rounded-md"
                            label={
                              <div>
                                <div>In-Store</div>
                                <div>Create promotion</div>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="both"
                            control={
                            <Radio checked={promotionTypeValue === 'both'}  
                            onChange={(e) => 
                              {
                                setPromotionTypeValue(e.target.value);
                                setPromotionTypeValueError(false);
                                setPromotionTypeValueHelperText('');
                              }
                            }
                            className="createPromotionCustomRadio"
                            />}
                            className="border-2	border-[#CCC] p-2 rounded-md"
                            label={
                              <div>
                                <div>Both</div>
                                <div>Create promotion</div>
                              </div>
                            }
                          />
                          <FormHelperText>{promotionTypeValueError==true?promotionTypeValueHelperText:''}</FormHelperText>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" margin="normal" className="w-[100%]">
                        <InputLabel id="promotion-locations-label">Select Locations</InputLabel>
                        <Select
                          labelId="promotion-locations-label"
                          multiple
                          id="promotionLocations"
                          label="promotionLocations"
                          name="promotionLocations"
                          value={promotionLocations}
                          onChange={(e) => setPromotionLocations(e.target.value)}
                          error={promotionLocationsError}
                        >
                          {onboardingStoreDetails?.locations?.map((location, index) =>  
                              <MenuItem value={location.formattedAddress}>{location.formattedAddress}</MenuItem>
                          )} 
                        </Select>
                        <FormHelperText>{promotionLocationsHelperText}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} marginTop={"6px"}>
                        <Checkbox  onChange={handleChange} name="selectAll"/> 
                        Select all locations
                      </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>

            <Divider style={{ margin: "30px 0px", color: "#e3e3e3" }} />

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">Audience and Tone</p>
                  <p className="createPromotionDescription">
                    Define your target, understand your audience, and set your content tone. Dive <br/>deeper into crafting your message.<br/>
                    <Link style={{ cursor: "pointer" }}>Learn more</Link>
                  </p>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" margin="normal" className="w-[100%]">
                          <InputLabel id="promotion-key-audience-label">Key Audience</InputLabel>
                          <Select
                            labelId="promotion-key-audience-label"
                            id="promotionKeyAudience"
                            label="Key Audience"
                            name="promotionKeyAudience"
                            value={promotionKeyAudience}
                            onChange={(e) => 
                              {
                                setPromotionKeyAudience(e.target.value);
                                setPromotionKeyAudienceError(false);
                                setPromotionKeyAudienceHelperText('');
                              }
                            }
                            error={promotionKeyAudienceError}
                          >
                            {createPromotionKeyAudienceList.map((promotionKeyAud, index) => 
                              <MenuItem key={promotionKeyAud.keyAudience} value={promotionKeyAud.keyAudience}>
                                <ListItemText 
                                  primary={promotionKeyAud.keyAudience}  
                                  secondary={<Typography variant="body2" sx={{fontFamily: 'DM Sans, sans-serif', fontSize: '12px', lineHeight: '0.43'}}>Years born : {promotionKeyAud.bornYear}</Typography>}
                                />
                              </MenuItem>
                            )}
                          </Select>
                          <FormHelperText>{promotionKeyAudienceHelperText}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} marginTop={"6px"}>
                        <FormControl variant="outlined" margin="normal" className="w-[100%]">
                          <InputLabel id="promotion-key-target-label">Key Target</InputLabel>
                          <Select
                            labelId="promotion-key-target-label"
                            multiple
                            id="promotionKeyTarget"
                            label="Key Target"
                            name="promotionKeyTarget"
                            value={promotionKeyTarget}
                            onChange={(e) => 
                              {
                                setPromotionKeyTarget(e.target.value);
                                setPromotionKeyTargetError(false);
                                setPromotionKeyTargetHelperText('');
                              }
                            }
                            error={promotionKeyTargetError}
                          >
                            {createPromotionKeyTargetsList.map((promotionKeyTarget, index) =>  
                              <MenuItem key={index} value={promotionKeyTarget}>{promotionKeyTarget}</MenuItem>
                            )} 
                          </Select>
                          <FormHelperText>{promotionKeyTargetHelperText}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" margin="normal" className="w-[100%]">
                          <InputLabel id="promotion-over-content-tone-label">Overall Content Tone</InputLabel>
                          <Select
                            labelId="promotion-over-content-tone-label"
                            multiple
                            id="promotionOverallContentTone"
                            label="Overall Content Tone"
                            name="promotionOverallContentTone"
                            value={promotionOverallContentTone}
                            onChange={(e) => 
                              {
                                setPromotionOverallContentTone(e.target.value);
                                setPromotionOverallContentToneError(false);
                                setPromotionOverallContentToneHelperText('');
                              }
                            }
                            error={promotionOverallContentToneError}
                          >
                          {createPromotionContentToneList.map((promotionContentTone, index) =>  
                            <MenuItem key={index} value={promotionContentTone}>{promotionContentTone}</MenuItem>
                          )} 
                          </Select>
                          <FormHelperText>{promotionOverallContentToneHelperText}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider style={{ margin: "30px 0px", color: "#e3e3e3" }} />

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">Promotion Duration</p>
                  <p className="createPromotionDescription">
                    Define when your promotion starts and ends to manage its duration and impact.<br/>
                    <Link style={{ cursor: "pointer" }}>Learn more</Link>
                  </p>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* Start Date */}
                        <DatePicker
                          label="Start Date"
                          value={promotionStartDate}
                          onChange={(newValue) => 
                            {
                              handleStartDateChange(newValue);
                            }
                          }
                          minDate={promotionToday}
                          textField={(params) => 
                          <TextField 
                           {...params} 
                           error={promotionStartDateError}
                           helperText={promotionStartDateHelperText}
                          />}
                        />
                        <FormHelperText>{promotionStartDateHelperText}</FormHelperText>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* End Date */}
                        <DatePicker
                          label="End Date"
                          value={promotionEndDate}
                          onChange={(newValue) => 
                            {
                              setPromotionEndDate(newValue);
                              setPromotionEndDateError(false);
                              setPromotionEndDateHelperText('');
                            }
                          }
                          minDate={promotionStartDate} // Ensures end date is after start date
                          textField={(params) => 
                          <TextField 
                            {...params} 
                            error={promotionEndDateError}
                            helperText={promotionEndDateHelperText}
                          />}
                        />
                        <FormHelperText>{promotionEndDateHelperText}</FormHelperText>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider style={{ margin: "30px 0px", color: "#e3e3e3" }} />

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">Delivery Platform</p>
                  <p className="createPromotionDescription">
                    Specify the platform, ensuraing it reaches your audience seamlessly.<br/>
                    <Link style={{ cursor: "pointer" }}>Learn more</Link>
                  </p>
                </Grid>
            
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 flex items-center"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <img src={process.env.REACT_APP_URL + 'archive-down 1.svg'} className="mr-3" alt="icon" />
                            <p>Email</p>
                          </div>
                          <div className="ml-32">
                            <Checkbox
                              name="email"
                              checked={deliveryPlatformCheckedValues.includes("email")}
                              onChange={handleDeliveryPlatformChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="ml-5 createPromotionCustomCheckbox"
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 flex items-center"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <img src={process.env.REACT_APP_URL + 'archive-down 1.svg'} className="mr-5" alt="icon" />
                            <p>SMS</p>
                          </div>
                          <div className="ml-32">
                            <Checkbox
                              name="sms"
                              checked={deliveryPlatformCheckedValues.includes("sms")}
                              onChange={handleDeliveryPlatformChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="ml-5 createPromotionCustomCheckbox"
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={2} >
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 flex items-center mt-6"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <img src={process.env.REACT_APP_URL + 'archive-down 1.svg'} className="mr-2" alt="icon" />
                            <p>WhatsApp</p>
                          </div>
                          <div className="ml-[92px]">
                            <Checkbox
                              name="whatsapp"
                              checked={deliveryPlatformCheckedValues.includes("whatsapp")}
                              onChange={handleDeliveryPlatformChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              className="ml-5 createPromotionCustomCheckbox"
                            />
                          </div>
                        </div>
                      </Box>
                      <FormHelperText>{promotionDeliveryPlatformError==true?promotionDeliveryPlatformHelperText:''}</FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider style={{ margin: "30px 0px", color: "#e3e3e3" }} />

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <p className="createPromotionTitle">Promotion Language</p>
                  <p className="createPromotionDescription">
                    Specify language, ensuraing it reaches your selected audience seamlessly.<br/>
                    <Link style={{ cursor: "pointer" }}>Learn more</Link>
                  </p>
                </Grid>
                 
                <Grid item xs={12} md={6}>
                  <RadioGroup
                    //value={selectedValue}
                    //onChange={handleRadioChange}
                    className="flex flex-row"
                  >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 h-[53px] flex items-center"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center ml-5">
                            <p>English</p>
                          </div>
                          <div className="ml-36 mt-3">
                            <FormControlLabel
                              value="English"
                              control={
                              <Radio 
                                checked={promotionLanguageValue === 'English'}  
                                onChange={(e) => 
                                  {
                                    setPromotionLanguageValue(e.target.value);
                                    setPromotionLanguageValueError(false);
                                    setPromotionLanguageValueHelperText('');
                                  }
                                }
                                className="createPromotionCustomRadio"
                              />}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 h-[53px] flex items-center"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center ml-5">
                            <p>Spanish</p>
                          </div>
                          <div className="ml-36 mt-3">
                            <FormControlLabel
                              value="Spanish"
                              control={
                              <Radio  
                              checked={promotionLanguageValue === 'Spanish'}  
                              onChange={(e) => 
                                {
                                  setPromotionLanguageValue(e.target.value);
                                  setPromotionLanguageValueError(false);
                                  setPromotionLanguageValueHelperText('');
                                }
                              }
                              className="createPromotionCustomRadio"
                              />}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={2} >
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 h-[53px] flex items-center mt-6"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center ml-5">
                            <p>French</p>
                          </div>
                          <div className="ml-36 mt-3">
                            <FormControlLabel
                              value="French"
                              control={
                              <Radio  
                              checked={promotionLanguageValue === 'French'}  
                              onChange={(e) => 
                                {
                                  setPromotionLanguageValue(e.target.value);
                                  setPromotionLanguageValueError(false);
                                  setPromotionLanguageValueHelperText('');
                                }
                              }
                              className="createPromotionCustomRadio"
                              />}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 h-[53px] flex items-center mt-6"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center ml-5">
                            <p>German</p>
                          </div>
                          <div className="ml-36 mt-3">
                            <FormControlLabel
                              value="German"
                              control={
                              <Radio 
                              checked={promotionLanguageValue === 'German'}  
                              onChange={(e) => 
                                {
                                  setPromotionLanguageValue(e.target.value);
                                  setPromotionLanguageValueError(false);
                                  setPromotionLanguageValueHelperText('');
                                }
                              }
                              className="createPromotionCustomRadio"
                              />}
                            />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={2} >
                    <Grid item xs={12} md={6}>
                      <Box
                        component="div"
                        className="border border-gray-300 p-1 h-[53px] flex items-center mt-6"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center ml-5">
                            <p>Chinese</p>
                          </div>
                          <div className="ml-[136px] mt-3">
                            <FormControlLabel
                              value="Chinese"
                              control={
                              <Radio 
                              checked={promotionLanguageValue === 'Chinese'}  
                              onChange={(e) => 
                                {
                                  setPromotionLanguageValue(e.target.value);
                                  setPromotionLanguageValueError(false);
                                  setPromotionLanguageValueHelperText('');
                                }
                              }
                              className="createPromotionCustomRadio"
                              />}
                            />
                          </div>
                        </div>
                      </Box>
                      <FormHelperText>{promotionLanguageValueError==true?promotionLanguageValueHelperText:''}</FormHelperText>
                    </Grid>
                  </Grid>
                </RadioGroup>
                </Grid>
              </Grid>
             
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "auto" }}
            >
              <Grid item xs={12} className="flex justify-end">
                <Button
                  type="submit"
                  variant="primary"
                  //onClick={handleSaveAndNext}
                >
                  Save and Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}

      {currentStep === 2 && (
        <Grid container className="root">
          {/* Second Page Content */}
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" className="title">
              Preview
            </Typography>
            <Typography variant="body2" className="description">
              The preview generated is based on content tailored for an
              individual customer. This serves as a representation of the
              customized content that will be generated for all customers.
              Additionally, you can also regenerate or edit this later while
              sending the promotion.
            </Typography>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <StyledTab value="one" label="SMS" />
              <StyledTab value="two" label="Email" />
              <StyledTab value="three" label="WhatsApp" />
            </StyledTabs>
            <Box className="contentTab">
              {value === "one" && (
                <div className="tabContent">
                  <div className="tabHeader">
                    <div className="headerIconLabel">
                      <EmailIcon style={{ color: "#f2a60c" }} />
                      <Typography className="tabTitle">SMS</Typography>
                    </div>
                    <Button variant="primary">Regenerate</Button>
                  </div>
                  <Divider className="tabDivider" />
                  <Typography>
                    Get ready for Father's Day with our sophisticated men's
                    collection at Pushsendclothing. Elevate your style game with
                    Exclusive apparel & accessories. Use code 'DAD12LOVE' for a
                    touch of Luxury. Shop now at Pushsendclothing Event starts
                    2024-06-01
                  </Typography>
                </div>
              )}
              {value === "two" && (
                <div className="tabContent">
                  <div className="tabHeader">
                    <div className="headerIconLabel">
                      <EmailIcon style={{ color: "#c71610" }} />
                      <Typography className="tabTitle">Email</Typography>
                    </div>
                    <Button variant="primary">Regenerate</Button>
                  </div>
                  <Divider className="tabDivider" />
                  <Typography>
                    Get ready for Father's Day with our sophisticated men's
                    collection at Pushsendclothing. Elevate your style game with
                    Exclusive apparel & accessories. Use code 'DAD12LOVE' for a
                    touch of Luxury. Shop now at Pushsendclothing Event starts
                    2024-06-01
                  </Typography>
                </div>
              )}
              {value === "three" && (
                <div className="tabContent">
                  <div className="tabHeader">
                    <div className="headerIconLabel">
                      <EmailIcon style={{ color: "#25D366" }} />
                      <Typography className="tabTitle">WhatsApp</Typography>
                    </div>
                    <Button variant="primary">Regenerate</Button>
                  </div>
                  <Divider className="tabDivider" />
                  <Typography>
                    Get ready for Father's Day with our sophisticated men's
                    collection at Pushsendclothing. Elevate your style game with
                    Exclusive apparel & accessories. Use code 'DAD12LOVE' for a
                    touch of Luxury. Shop now at Pushsendclothing Event starts
                    2024-06-01
                  </Typography>
                </div>
              )}
            </Box>
          </Box>

          <Grid item xs={12} className="flex justify-between">
            <Button
              variant="secondary"
              onClick={() => setCurrentStep(1)}
              style={{ marginLeft: "10px" }}
            >
              Previous
            </Button>
            <Button
              variant="primary"
              onClick={() => alert("Final submission action")}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CreatePromotion;
