import React from 'react';
import { Box, Grid } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import UpsellProbabilityComponent from '../UpsellProbabilityComponent/UpsellProbabilityComponent';
import ChurnPredictionScoreComponent from '../ChurnPredictionScoreComponent/ChurnPredictionScoreComponent';
import OrderFragmentationGauge from '../AnalyticsChartsComponent/OrderFragmentationGauge';
import PriceSensitivityBarChart from '../AnalyticsChartsComponent/PriceSensitivityBarChart';
import "./ProductiveAnalysis.css";


const ProductiveAnalysis = () => {


    return (
        <div>
            <Box className="productiveAnalysisContainer">
                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className='weekendWarriorsContainer'>
                            <div className='weekendWarriorsTitle'>Weekend Warriors</div>
                            <div className='gaugeContainer'>
                                <Gauge
                                    width={250}
                                    height={300}
                                    value={75}
                                    innerRadius="60%"
                                    startAngle={-100}
                                    endAngle={260}
                                    sx={() => ({
                                        [`& .${gaugeClasses.valueArc}`]: {
                                            fill: '#7B65FF',
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className='upsellProbabilityContainer'>
                            <UpsellProbabilityComponent />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className='priceSensitivityContainer'>
                            <div className='priceSensitivityTitle'>Price Sensitivity Score</div>
                            <PriceSensitivityBarChart />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className='churnPredictionContainer'>
                            <ChurnPredictionScoreComponent />
                        </div>
                    </Grid>
                </Grid>
                <div className='fragmentationContainer'>
                    <div className='fragmentationTitle'>Order Fragmentation</div>
                    <div className='fragmentationSection'>
                        <div className='fragmentationSectionItem'>
                            <OrderFragmentationGauge value={30} />
                            <div className='fragmentationItemDescription'>One unit buy at a time</div>
                        </div>
                        <div className='fragmentationSectionItem'>
                            <OrderFragmentationGauge value={72} />
                            <div className='fragmentationItemDescription'>Multiple unit buy at a time</div>
                        </div>
                        <div className='fragmentationSectionItem'>
                            <OrderFragmentationGauge value={50} />
                            <div className='fragmentationItemDescription'>Offline Buyer</div>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default ProductiveAnalysis
