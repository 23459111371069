
import * as React from 'react';
import {
    GaugeContainer,
    GaugeValueArc,
    GaugeReferenceArc,
    useGaugeState,
    gaugeClasses
} from '@mui/x-charts/Gauge';

const OrderFragmentationGauge = ({ value }) => {


    function GaugePointer() {
        const { valueAngle, outerRadius, cx, cy } = useGaugeState();

        if (valueAngle === null) {
            return null;
        }

        const pointerBaseWidth = 8;
        const pointerLength = outerRadius * 0.6;
        


        return (
            <g>
                <circle cx={cx} cy={cy} r={12} fill="#8c8c8c" />
                <path
                    d={`M ${cx} ${cy} L ${cx + pointerLength * Math.sin(valueAngle)} ${cy - pointerLength * Math.cos(valueAngle)}`}
                    stroke="#8c8c8c"
                    strokeWidth={10}
                // strikethroughThickness={5}
                // strokeDasharray={5}
                // strokeLinecap='fjsdfkjsdkj'
                // strokeLinejoin='red'
                // strokeMiterlimit={10}
                // strokeOpacity={1}
                />
            </g>
        );
    }


    return (
        <div>
            <GaugeContainer
                width={200}
                height={200}
                startAngle={-110}
                endAngle={110}
                value={value}

            >
                <GaugeReferenceArc strokeWidth={10} />
                <GaugeValueArc />
                <GaugePointer />
            </GaugeContainer>
        </div>
    );
};

export default OrderFragmentationGauge;
