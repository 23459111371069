import React from 'react';
import { Grid } from '@mui/material';
import { useState } from 'react';
import ProductiveAnalysis from '../../Components/ProductiveAnalysisComponent/ProductiveAnalysis';
import StoreDemographics from "../../Components/StoreDemographicsComponent/StoreDemographics";
import "./AnalyticsPage.css";


const AnalyticsPage = () => {

    const [activeSection, setActiveSection] = useState("productiveAnalysis");

    const handleButtonClick = (section) => {
        setActiveSection(section);
    };
    return (
        <>
            <div className='analyticsPageContainer' >
                <Grid container className='analyticsGridContainer'>
                    <Grid item xs={12} md={6}>
                        <div className='promotionInfoSection'>
                            <div className='promotionGreetingText'>
                                Hi Erica
                            </div>
                            <p className="promotionWebsite">designhaven.com</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className="promotionActionWrapper">
                        <div className="promotionActionSection">
                            <div className="promotionActionTitle">Create New Promotion Today</div>
                            <button className="promotionCreateButton">Create</button>
                        </div>
                        <div>
                            <img src={process.env.REACT_APP_URL + 'PromotionSound.svg'} />
                        </div>
                    </Grid>
                </Grid>
                <div className='analyticsCardContainer'>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'storeSuperProfile.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Store Super Profile</div>
                    </div>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'conversationBox.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Conversation Box</div>
                    </div>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'promotionCalender.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Promotions Calendar</div>
                    </div>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'suggestedPromotion.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Suggested Promotions</div>
                    </div>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'customer.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Customers</div>
                    </div>
                    <div className='analyticsItemCard'>
                        <img src={process.env.REACT_APP_URL + 'setting.svg'} className="analyticsItemCardImage" />
                        <div className="analyticsItemCardTitle">Settings</div>
                    </div>
                </div>
                <div className='analyticsButtonContainer'>
                    <div className="analyticsButtonGroup">
                        <button
                            className={`button ${activeSection === 'productiveAnalysis' ? 'productiveButtonActive' : 'productiveButtonInactive'}`}
                            onClick={() => handleButtonClick('productiveAnalysis')}
                        >
                            Productive Analysis
                        </button>
                        <button
                            className={`button ${activeSection === 'storeDemographics' ? 'storeButtonActive' : 'storeButtonInactive'}`}
                            onClick={() => handleButtonClick('storeDemographics')}
                        >
                            Store Demographics</button>
                    </div>
                    <div className='additionalContent'>
                        {activeSection === 'productiveAnalysis' && <ProductiveAnalysis />}
                        {activeSection === 'storeDemographics' && <StoreDemographics />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalyticsPage
