import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import "./ChurnPredictionScoreComponent.css";

const ChurnPredictionScoreComponent = () => {
    const [monthSelect, setMonthSelect] = useState('');

    const handleChange = (event) => {
        setMonthSelect(event.target.value);
    };
    return (
        <div>
            <div className='churnPredictionHeading' >
                <div className='churnPredictionTitle'>Churn Prediction Score</div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel>Month</InputLabel>
                        <Select
                            value={monthSelect}
                            label="Month"
                            onChange={handleChange}
                            className='churnPredictionDropDown'
                        >
                            <MenuItem value={1}>January</MenuItem>
                            <MenuItem value={2}>February</MenuItem>
                            <MenuItem value={3}>March</MenuItem>
                            <MenuItem value={4}>April</MenuItem>
                            <MenuItem value={5}>May</MenuItem>
                            <MenuItem value={6}>June</MenuItem>
                            <MenuItem value={7}>July</MenuItem>
                            <MenuItem value={8}>August</MenuItem>
                            <MenuItem value={9}>September</MenuItem>
                            <MenuItem value={10}>October</MenuItem>
                            <MenuItem value={11}>November</MenuItem>
                            <MenuItem value={12}>December</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="churnproductDetailsContainer">
                <div className='churnproductHeadingWrapper'>
                    <div className="churnproductHeadingLabel">Product</div>
                    <div className="churnsalesActionDescription">Increase Sale</div>
                </div>
                <div className='churnproductListingContainer'>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'millerLinenDickeyJacket.png'}
                                className='churnproductImage'
                            />
                            <div className='churnproductName'>Miller Linen Dickey Jacket</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />

                        </div>
                    </div>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'ashaSandal.png'}
                                className='churnproductImage' />
                            <div className='churnproductName'>Asha Sandal</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />

                        </div>
                    </div>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'ashaSandal.png'}
                                className='churnproductImage' />
                            <div className='churnproductName'>Asha Sandal</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />

                        </div>
                    </div>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'ashaSandal.png'}
                                className='churnproductImage' />
                            <div className='churnproductName'>Asha Sandal</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />

                        </div>
                    </div>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'ashaSandal.png'}
                                className='churnproductImage' />
                            <div className='churnproductName'>Asha Sandal</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />
                        </div>
                    </div>
                    <div className='churnproductCard'>
                        <div className='churnproductCardContent'>
                            <img src={process.env.REACT_APP_URL + 'ashaSandal.png'}
                                className='churnproductImage' />
                            <div className='churnproductName'>Asha Sandal</div>
                        </div>
                        <div className="churnsparklineContainer">
                            <SparkLineChart data={[0, 2, 1, 3]} colors={['red']} height={50} width={100} />
                            <SparkLineChart data={[1, 6, 5, 9]} colors={['#8c8c8c']} height={50} width={100} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChurnPredictionScoreComponent
