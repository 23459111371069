import React,{useState,useEffect} from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const OrderListBarCharts = () => {
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 600 ? "320" : 350);
    const [chartHeight, setChartHeight] = useState(window.innerWidth < 600 ? "300" : 300);

    const uData = [90, 50, 60, 57, 88, 63, 44];
    const pData = [124, 113, 98, 139, 98, 100, 83];
    const uData1 = [40, 30, 90, 77, 80, 63, 90];
    const pData1 = [94, 63, 48, 79, 88, 68, 73];
    const uData2 = [90, 80, 100, 70, 88, 70, 84];
    const orderListMonths = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun',
    ];

    const handleResize = () => {
        setChartWidth(window.innerWidth < 600 ? "320" : 350);
        setChartHeight(window.innerWidth < 600 ? "300" : 300);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <BarChart
                sx={() => ({
                    [`.${axisClasses.root}`]: {
                        [`.${axisClasses.tickLabel}`]: {
                            fill: '#5F73AE',
                        },
                    },
                })}
                width={chartWidth}
                height={chartHeight}
                grid={{ horizontal: true }}
                series={[
                    { data: pData, stack: 'total', color: '#711FFF' },
                    { data: uData, stack: 'total', color: "#8844fc" },
                    { data: pData1, stack: 'total', color: "#955afa" },
                    { data: uData1, stack: 'total', color: "#ab7cfc" },
                    { data: uData2, stack: 'total', color: "#c1a0fa" },
                ]}
                xAxis={[{
                    data: orderListMonths,
                    scaleType: 'band',
                    disableLine: true,
                    disableTicks: true,
                    fill: "red",
                    tickFontSize: "12",
                    tickLabelPlacement: "middle",
                    tickLabelStyle: {
                        color: "#5F73AE",
                        fontWeight: "bold",
                    },
                    label: "10% 20% 40% 60% 80%",
                    labelFontSize: "12",
                }]}
                yAxis={[{
                    min: 0,
                    max: 500,
                    disableLine: true,
                    disableTicks: true,
                    tickFontSize: "12",
                    tickLabelPlacement: "middle",
                    tickLabelStyle: {
                        color: "#5F73AE",
                        fontWeight: "bold",
                    }
                }]}
            />

        </div>
    )
}

export default OrderListBarCharts
